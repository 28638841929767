import { GetRegionDirectoryList,CreateRegion,UpdateRegion,UpdateStatusRegion,GetRegionDirectoryListDropDown } from '@/actions/directoryActions.js'


const state ={
    regionList :{
        docs: [],
        totalPages : 0,
    },
    regionData:{},
    regionDataUpdate : {},
    regionDropDownList : [],
    search : '',
    page : 1
}
const getters = {
    regionList : state => state.regionList,
    regionDataUpdate : state => state.regionDataUpdate,
    regionSearch : state => state.search,
    regionPage : state => state.page,
    regionDropDownList : state => state.regionDropDownList
}
const actions = {
    async GetRegionList({commit},data = {search : '',  page : 1 }){
        let search = data.search || ''
        let page = data.page || 1

        commit('setRegionPage',page)
        commit('setRegionSearch',search)

        const getData = await GetRegionDirectoryList(search, page).catch()
  
        if(getData){
            commit('setRegionList',getData)
        }
    },
    async GetRegionListDropdown({commit}){
        const data = await GetRegionDirectoryListDropDown().catch()
        if(data){
            commit('setRegionDropDownList', data)
        }
    },
    async CreateRegionRun ({dispatch},data){
        const create = await CreateRegion(data).catch()
        if(create=="OK"){
            dispatch('GetRegionList')
        }

        return create
    },
    async UpdateRegionRun ({dispatch},data){
        const {regionPage ,regionSearch} = this.getters
        const update = await UpdateRegion(data).catch()
        if(update=="OK"){
            dispatch('GetRegionList', {page : regionPage, search : regionSearch})
        }

        return update
    },
    async UpdateRegionStatusRun (obj,data){
        const update = await UpdateStatusRegion(data).catch()
        if(update=="OK"){
            const regionList= this.getters.regionList.docs
            const index = regionList.findIndex((u)=>u._id===data)
            regionList[index].status = !regionList[index].status
        }

        return update
    },
    SetRegionUpdate({commit},data){
        commit('setRegionDataUpdate',data)
    }
}
const mutations = {
    setRegionList : (state, data) => (state.regionList = data),
    setRegionDataUpdate : (state, data) => (state.regionDataUpdate = data),
    setRegionPage : (state, data) => (state.page = data),
    setRegionSearch : (state, data) => (state.search = data),
    setRegionDropDownList : (state,data) => (state.regionDropDownList = data)
}


export default {
    state,
    getters,
    actions,
    mutations
}