<template>
    <div class="card">
        <div class="card-body">
            <div class="pb-3">
                <input type="text" placeholder="Seach Region here..." class="form-control" @keyup="searchRegion($event.target.value)"/>
            </div>
            <div class="clearfix"></div>
            <div class="d-flex justify-content-end" v-if="regionList.totalDocs">
                <Pagination
                    :paginationDetails="{totalPages : regionList.totalPages, page : regionList.page}"
                    :changePage="ChangePage" 
                ></Pagination>
            </div>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Created At</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>  
                    <tbody class="text-capitalize">
                        <tr v-if="regionList.totalDocs==0">
                            <td class="text-center" colspan="4">No Data Found</td>
                        </tr>
                        <tr v-for="(item, index) in  regionList.docs" :key="index">
                            <td>{{item.regionName}}</td>
                            <td>{{formatDate(item.createdAt)}}</td>
                            <td>
                                <span class="badge bg-primary text-white" v-if="item.status">Active</span>
                                <span class="badge bg-warning text-white" v-else>Inactive</span>
                            </td>
                            <td>
                                <button class="btn btn-primary pe-1 ps-1 pt-0 pb-0 me-1" @click="SetRegionUpdate({_id : item._id, regionName : item.regionName})" title="Edit" data-bs-toggle="modal" data-bs-target="#UpdateRegionModal"><i class="fa-solid fa-edit"></i></button>
                                <button class="btn btn-danger pe-1 ps-1 pt-0 pb-0 me-1" @click="ChangeStatus(item._id)" title="Change Status"><i class="fa-solid fa-lock"></i></button>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Pagination from '@/components/layout/Pagination.vue'
import {ReformatDate} from '@/helpers/utilities.js'
export default {
    name : 'RegionList',
    components : {
        Pagination
    },
    data(){
        return {
            timer : null
        }
    },
    methods : {
        ...mapActions(['GetRegionList','UpdateRegionStatusRun','SetRegionUpdate']),
        ChangePage (page){
            this.GetRegionList({search : this.regionSearch, page : page}).catch()
        },
        timerSetter(typed, callback, seachEmpty = false){
            const self = this
            if(!seachEmpty){
                if(!typed){
                return
                }
            }
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        searchRegion(search){
            const self = this
            self.timerSetter(search,function(){
                self.GetRegionList({search : search, page : 1}).catch()
            },true)
        },
        formatDate(value){
            return ReformatDate(value)
        },
        ChangeStatus(id){
            const self = this
            self.$swal({
                title: 'Change Status?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Change',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return self.UpdateRegionStatusRun(id).then((res)=>{
                        if(res!="OK"){
                          self.$swal('Error!', res, 'warning')  
                        }
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.$swal('Status Changed!', '', 'success')
                }
            })
        }
    },
    computed : mapGetters(['regionList','regionSearch'])
    
}
</script>