import UserList from '@/components/directoryInfo/User/UserList.vue'
import Modal from '@/components/layout/Modal.vue'
import Select2 from '@/components/layout/Select2.vue'
import {mapGetters, mapActions} from 'vuex'
import {SearchUserFromKC} from '@/actions/projectActions.js'
import {CreateUser, GetUserDirectoryUserOne, UpdateUser ,SearchCompanies} from '@/actions/directoryActions.js'
import { IsValidEmail, LinkList,CompanyTypeList , CompanyType} from '@/helpers/utilities.js'
import AutocompleteSelect from '@/components/layout/AutocompleteSelect.vue'
export default {
  name: 'User',
  components: {
    UserList,
    Modal,
    Select2,
    AutocompleteSelect
  },
  data(){
    return {
    isUpdateClicked : false,
    invalidEmail : false,
    newUser : {
        companyType : '1',
        roleId : '',
        contacts : [''],
        officeId : ''
    },
    SaveClicked : false,
    addedContacts : [{value : ''}],
    updatedUser : {
        companyType : '1',
        roleId : '',
        contacts : [''],
        officeId : ''
    },
    isUpdateLoading : false,
    updatedContacts : [{value : ''}],
    CompanyTypeList : CompanyTypeList,
    CompanyType : CompanyType
    }
  },
  mounted : function(){
    this.GetUserList().catch()
    this.GetUserDirectoryInitial().catch()
    this.$store.commit('setActiveLink',LinkList.MAIN_COMPANY)
  },
  methods : {
    ...mapActions(['GetUserList','GetUserDirectoryInitial','UpdateUserRun']),
    SearchCompanies(search){
        return SearchCompanies({search : search})
    },
    updateUser(e){
        const self = this
        const btn = e.target
        self.isUpdateClicked = true
        const { _id ,companyType, companyName, companyAddress, firstName, lastName, roleId, email} = self.updatedUser
        if(!_id ){
            return
        }

        if(companyType==CompanyType.MMOSER){
            if(!companyType || !firstName || !lastName || !roleId  || !IsValidEmail(email) ){
                return
            }
        }
        else if(companyType==CompanyType.CLIENT){
            if(!companyType || !companyName || !companyAddress || !firstName || !lastName  || !IsValidEmail(email) ){
                return
            }
        }else{
            if(!companyType || !companyName || !companyAddress || !firstName || !lastName   || !IsValidEmail(email) ){
                return
            }
        }
        self.isUpdateClicked = false
        btn.disabled = true

        self.updatedUser.contacts = self.updatedContacts.filter(u=>u.value!='').map(u=>u.value)

        UpdateUser(self.updatedUser).then((res)=>{
            btn.disabled = false
            if(res=="OK"){
                self.$refs.closeModalUpdate.click()
                self.GetUserList().catch()
            }else{
                self.$swal('Error',res,'warning')
            }
        })
        
    },
    OnCompanyClick(item){
        this.updatedUser.companyName = item.companyName
        this.updatedUser.companyAddress = item.address
    },
    OnCompanyClear(){
        this.updatedUser.companyAddress = ''
    },
    AddNewLineContact(type){
        if(type==1)
            this.addedContacts.push({value : ''})
        else
            this.updatedContacts.push({value : ''})
    },
    RemoveContact(key, type){
        if(type==1){
            this.addedContacts.splice(key,1)
            this.newUser.contacts.splice(key,1)
        }
        else{
            this.updatedContacts.splice(key,1)
            this.updatedUser.contacts.splice(key,1)
        }
    },
    CheckEmail(value){
      if(value==""){
        return
      }
      this.invalidEmail = !IsValidEmail(value)
    },
    SearchFromKc(search){
        return SearchUserFromKC(search)
    },
    IsValidEmail (value){
        return IsValidEmail(value)
    },
    AppendSelectedKcUser(data){
        this.newUser.firstName = data.firstName
        this.newUser.lastName = data.lastName
        this.newUser.photoUrl = data.photo
        this.newUser.email = data.email
        this.addedContacts = [{value : ''}]
        this.newUser.contacts  = []
        if(data.mobileNo!=""){
            this.addedContacts[0].value = data.mobileNo
            this.newUser.contacts.push(data.mobileNo)
        }
    },
    SaveNewUser(e){
      const self = this
      const btn = e.target
      self.SaveClicked = true
      const { companyType, companyName,companyAddress, firstName, lastName, roleId, email} = self.newUser

      if(companyType==CompanyType.MMOSER){
          if(!companyType || !firstName || !lastName || !roleId  || !IsValidEmail(email) ){
              return
          }
      }
      else if(companyType==CompanyType.CLIENT){
          if(!companyType || !companyName ||!companyAddress || !firstName || !lastName  || !IsValidEmail(email) ){
              return
          }
      }else{
          if(!companyType || !companyName || !companyAddress || !firstName || !lastName   || !IsValidEmail(email) ){
              return
          }
      }

      btn.disabled = true

      self.newUser.contacts = self.addedContacts.filter(u=>u.value!='').map(u=>u.value)

      CreateUser(self.newUser).then((res)=>{
        btn.disabled = false
        if(res=="OK"){
            self.$refs.closeModalCreate.click()
            self.GetUserList().catch()
        }else{
            self.$swal('Error',res,'warning')
        }
      })
      
    },
    
    OnNewCompanyClick(item){
        this.newUser.companyName = item.companyName
        this.newUser.companyAddress = item.address
    },
    OnNewCompanyClear(){
        this.newUser.companyAddress = ''
    },
    OpenCreateModal(){
        this.newUser =  {
            companyType : '1',
            roleId : '',
            contacts : [''],
            officeId : ''
        }
        this.addedContacts = [{value : ''}]
    },
    OpenUpdateModal(id){
        const self = this
        self.updatedUser =  {
            companyType : '1',
            roleId : '',
            contacts : [''],
            officeId : ''
        }

        
        self.updatedContacts = []
        self.isUpdateLoading = true
        GetUserDirectoryUserOne(id).then((res)=>{
            self.updatedUser = res
            self.updatedUser.companyId = res.company?._id
            self.updatedUser.companyName = res.company?.companyName
            self.updatedUser.companyAddress = res.company?.address
            if(!self.updatedUser.contacts){
                self.updatedUser.contacts = []
            }          
            if(self.updatedUser.contacts.length!=0){
                for(let x = 0; x< self.updatedUser.contacts.length; x++){
                    self.updatedContacts.push({value : self.updatedUser.contacts[x]})
                }
            }else{
                self.updatedContacts = [{value : ''}]
            }

            self.isUpdateLoading = false
            
        })
    }
  },
  computed : mapGetters(['userList','userDataUpdate', 'offices','roles'])
}