<template>
    <div class="card">
        <div class="card-body">
            <div class="form-group pb-1">
                <label>Region Name</label>
                <input type="text" class="form-control" v-model="regionData.regionName" placeholder="Region Name"/>
                <span class="text-warning" v-if="!regionData.regionName && isClicked">Required</span>
            </div>
            <div class="d-flex justify-content-end pt-2">
                <button ref="saveBtn" @click="CreateRegion" class="btn btn-primary"><i class="fa-solid fa-floppy-disk"></i> Save Region</button>

            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    name : 'RegionForm',
    data(){
        return {
            regionData : {},
            isClicked :false
        }
    },
    methods : {
        ...mapActions(['CreateRegionRun']),
        CreateRegion(){
            const self = this
            const btn = self.$refs.saveBtn
            let data = self.regionData
            self.isClicked = true
            if(!data.regionName){
                return
            }
            
            btn.disable = true
            self.isClicked = false

            self.CreateRegionRun(data).then((res)=>{
                if(res=="OK"){
                    self.regionData = {}
                }else{
                    self.$swal('Error',res,'warning')
                }
            })
        }
    }
}
</script>