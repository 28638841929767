<template>
    <nav id="sidebar" class="bg-white" v-if="isBig">
        <div class="d-flex flex-column flex-shrink-0 sidebar-height">
            <a href="#" class="d-flex align-items-center pb-3 mb-md-0  link-dark text-decoration-none border-bottom ps-3 pt-2 ">
                <img src="/lens.svg" style="height: 2rem;" class="">
                <span class="d-none d-lg-block d-xl-block "></span>
            </a>
            <ul class="nav nav-pills flex-column mb-auto border-end" style="height:100%">
                <h6 class="dropdown-header text-capitalize">Main</h6>
                <NavLink
                icon="fa-solid fa-building"
                navName="Projects"
                :selected="activeLink"
                :linkId="LinkList.MAIN_DASHBOARD"
                @click="OnNavLinkClick(projectUrl + '/')"
                />
                <NavLink
                data-bs-toggle="collapse" href="#CompanySubMenu"
                icon="fa-solid fa-user-tie"
                navName="Organisation"
                :selected="activeLink"
                :linkId="LinkList.MAIN_COMPANY"
                />
                <div class="collapse ps-3" id="CompanySubMenu">
                    <NavLink
                    navName="User"
                    :selected="activeLink"
                    @click="OnNavLinkClick('/directory/user',true)"
                    />
                    <NavLink
                    navName="Office"
                    :selected="activeLink"
                    @click="OnNavLinkClick('/directory/office',true)"
                    />
                    <NavLink
                    navName="Region"
                    :selected="activeLink"
                    @click="OnNavLinkClick('/directory/region',true)"
                    />
                    <NavLink
                    navName="Company List"
                    :selected="activeLink"
                    @click="OnNavLinkClick('/directory/Company',true)"
                    />
                </div>
                <NavLink
                icon="fa-solid fa-file-invoice-dollar"
                navName="Cost Codes"
                :selected="activeLink"
                :linkId="LinkList.COST_CODES"
                @click="OnNavLinkClick('/directory/costcode',true)"
                />
            </ul>
            <div class="sidebar-footer">
                <hr />
                <div class="d-flex justify-content-between pt-0 p-3" >
                    <div class="dropdown">
                        <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle text-capitalize" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa-solid fa-user pe-2"></i>
                            <strong>{{userInfo?.firstName || 'User'}}</strong>
                        </a>
                        <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                            <h6 class="dropdown-header text-capitalize">{{userInfo?.fullname || 'User'}}</h6>
                            <li><a class="dropdown-item" href="#">Settings</a></li>
                            <li><hr class="dropdown-divider"></li>
                            <li @click="LogoutUser"><a class="dropdown-item" href="#">Sign out</a></li>
                        </ul>
                    </div>
                    <div class="tooltip-custom" @click="changeView">
                        <span role="button" class="ms-3"><i class="fa-solid fa-angles-left fa-xl"></i></span>
                        <span class="tooltiptext-custom">Minimize</span>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <nav id="sidebar-mini" class="bg-white shadow-sm" v-else>
        <div class="d-flex flex-column flex-shrink-0 sidebar-height" >
            <a href="#" class="d-flex align-items-center pb-2 mb-md-0  link-dark text-decoration-none p-3 pb-0 border-bottom">
                <img src="/lens.svg" style="height: 2rem;" class="">
                <span class="d-none d-lg-block d-xl-block "></span>
            </a>
            <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
                <NavLinkIcon
                icon="fa-solid fa-building"
                :navName="$t('Projects')"
                :selected="activeLink"
                :linkId="LinkList.MAIN_DASHBOARD"
                @click="OnNavLinkClick(projectUrl + '/')"
                />
                
                <div class="dropend">
                    <NavLinkIcon
                    data-bs-toggle="dropdown"
                    icon="fa-solid fa-user-tie"
                    :navName="$t('Organisation')"
                    :selected="activeLink"
                    :linkId="LinkList.MAIN_COMPANY"
                    />
                    <ul class="dropdown-menu text-small shadow" offset="5px">
                        <li>
                            <router-link :to="'/directory/user'" class="dropdown-item">
                                {{$t('User')}}
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="'/directory/office'" class="dropdown-item">
                                {{$t('Office')}}
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="'/directory/region'" class="dropdown-item" >
                                {{$t('Region')}}
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="'/directory/company'" class="dropdown-item" >
                                {{$t('Company List')}}
                            </router-link>
                        </li>
                    </ul>
                </div>
                <NavLinkIcon
                icon="fa-solid fa-file-invoice-dollar"
                :navName="$t('Cost Code')"
                :selected="activeLink"
                :linkId="LinkList.COST_CODES"
                @click="OnNavLinkClick('/directory/costcode',true)"
                />
                <NavLinkIcon
                data-bs-toggle="dropdown"
                icon="fa-solid fa-laptop-file"
                :navName="$t('Bidding')"
                :selected="activeLink"
                :linkId="LinkList.HIGH_LEVEL_ESTIMATES"
                :link="boqUrl + '/high-level-estimates'"
                @click="OnNavLinkClick(boqUrl + '/high-level-estimates')"
                />
                <div class="dropend">
                    <NavLinkIcon
                    data-bs-toggle="dropdown"
                    icon="fa-solid fa-wrench" 
                    :navName="$t('Administrative Page')"
                    :selected="activeLink"
                    />
                    <ul class="dropdown-menu text-small shadow" offset="5px">
                        <li><a class="dropdown-item" :href="dotNetUrl +'/admin/taxrules'"> {{ $t('Tax Rules') }}</a></li>
                        <li><a class="dropdown-item" :href="dotNetUrl +'/admin/bankinformation'"> {{ $t('Bank Information') }}</a></li>
                        <li><a class="dropdown-item" :href="dotNetUrl +'/admin/currency/rates'"> {{ $t('Currency Rates') }}</a></li>
                        <li><a class="dropdown-item" :href="dotNetUrl +'/admin/signatures'"> {{ $t('Staff Signatures') }}</a></li>
                    </ul>
                </div>
            </ul>
            <div class="dropend border-top">
                <a href="#" class="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle text-capitalize" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fa-solid fa-user"></i>
                </a>
                <ul class="dropdown-menu text-small shadow" >
                    <h6 class="dropdown-header text-capitalize">{{userInfo?.fullname || 'User'}}</h6>
                    <li><a class="dropdown-item" href="#">{{$t('Settings')}}</a></li>
                    <li><hr class="dropdown-divider"></li>
                    <li @click="LogoutUser"><a class="dropdown-item" href="#">{{$t('Sign Out')}}</a></li>
                </ul>
            </div>
            <!-- <div class=" pb-2 border-top pt-2 tooltip-custom" @click="changeView">
                <span role="button" class="ms-3"><i class="fa-solid fa-angles-right fa-xl"></i></span>
                <span class="tooltiptext-custom">Expand</span>
            </div> -->
        </div>
    </nav>
</template>

<script>
import { GetMyUserInfo,LogOut } from '@/actions/userActions.js'
import NavLink from '@/components/layout/NavLink.vue'
import NavLinkIcon from '@/components/layout/NavLinkIcon.vue'
import { LinkList } from '@/helpers/utilities.js'
import {  useCookies } from "vue3-cookies"
import  { mapGetters, } from 'vuex'
import 'dotenv/config'
export default {
    name : "SideBar",
    components : {
        NavLink,
        NavLinkIcon
    },
    data(){

        return {
            userInfo : {},
            isBig : false,
            projectUrl : process.env.VUE_APP_PROJECT_URL,
            boqUrl : process.env.VUE_APP_BOQ_URL,
            dotNetUrl : process.env.VUE_APP_DOTNET_URL,
            isHover : false
        }
    },
    mounted : function(){
        const self = this
        self.GetMyInfo()
    },
    methods : {
        changeView(){
            this.isBig = !this.isBig
        },
        GetMyInfo (){
            const self = this
            GetMyUserInfo().then((res)=>{
                self.userInfo = res
            })
        },
        LogoutUser (){
            const { cookies } = useCookies()
            const self = this
            LogOut().then(()=>{
                cookies.remove('_auzer')
                
                window.location.href = self.projectUrl + '/signout?signout=yes'

            })
        },
        isActive(linkId, classList){
            if(this.activeLink==linkId)
                return classList + ' active'
            else
                return classList
        },
        OnNavLinkClick(url, isRouterLink = false){
            if(isRouterLink){
                this.$router.push(url)
            }else{
                window.location.href = url
            }
        }
    },
    computed : {
        ...mapGetters(['activeLink','projectInfo']),
        LinkList : ()=>LinkList
    }
}
</script>
<style >
</style>