import axios from 'axios'
import { CreateToken } from '../helpers/jwtHelpers.js'
import 'dotenv/config'

const MAIN_API = process.env.VUE_APP_REST_MAIN_API
// const TOKEN_INDEX_REST = 1
const TOKEN_INDEX_MAIN = 0

axios.defaults.withCredentials = true

export const GetMyUserInfo = async () =>{
    return axios.get(`${MAIN_API}/api/user/me`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const GetUserByName = async (search) =>{
    return axios.get(`${MAIN_API}/api/user/findByName?search=${search}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const CheckSession = async () =>{
    return axios.get(`${MAIN_API}/api/auth/session`)
    .then(()=>{
        return true
    }).catch(()=>{ return false })
}

export const LogOut = async () =>{
    return axios.get(`${MAIN_API}/api/auth/logout`)
    .then(()=>{
        return true
    }).catch(()=>{ return false })
}