<template>
    <div class="card">
        <div class="card-body">
            <div class="pb-3">
                <input type="text" placeholder="Seach Office here..." class="form-control" @keyup="searchOffice($event.target.value)"/>
            </div>
            <div class="d-flex justify-content-end">
                <Pagination
                    :paginationDetails="{totalPages : officeList.totalPages, page : officeList.page}"
                    :changePage="ChangePage" 
                ></Pagination>
            </div>
            <div class="clearfix"></div>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Region</th>
                            <th>Created At</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="text-capitalize">
                        <tr v-if="officeList.totalDocs==0">
                            <td class="text-center" colspan="5">No Data Found</td>
                        </tr>
                        <tr v-for="(item, key) in officeList.docs" :key="key">
                            <td>{{item.officeName}}</td>
                            <td>{{item.regionId.regionName}}</td>
                            <td>{{formatDate(item.createdAt)}}</td>
                            <td>
                                <span class="badge bg-primary text-white" v-if="item.status">Active</span>
                                <span class="badge bg-danger text-white" v-else>Inactive</span>
                            </td>
                            <td>
                                <button class="btn btn-primary pe-1 ps-1 pt-0 pb-0 me-1" 
                                @click="AppendData({_id : item._id, officeName : item.officeName, regionId : item.regionId._id})" 
                                data-bs-toggle="modal" 
                                data-bs-target="#UpdateOfficeModal" 
                                title="Edit">
                                    <i class="fa-solid fa-edit"></i>
                                </button>
                                <button class="btn btn-danger pe-1 ps-1 pt-0 pb-0 me-1" title="Change Status" @click="ChangeStatus(item._id)"><i class="fa-solid fa-lock"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Pagination from '@/components/layout/Pagination.vue'
import {ReformatDate} from '@/helpers/utilities.js'
export default {
    name : 'OfficeList',
    components : {
        Pagination
    },
    data(){
        return{
            timer : null
        }
    },
    methods : {
        ...mapActions(['GetOfficeList','UpdateOfficeStatus','AppendOfficeUpdateData']),
        ChangePage (page){
            this.GetOfficeList({search : this.officeSearch, page : page}).catch()
        },
        timerSetter(typed, callback, seachEmpty = false){
            const self = this
            if(!seachEmpty){
                if(!typed){
                return
                }
            }
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        searchOffice(search){
            const self = this
            self.timerSetter(search,function(){
                self.GetOfficeList({search : search, page : 1}).catch()
            },true)
        },
        formatDate(value){
            return ReformatDate(value)
        },
        AppendData(data){
            this.AppendOfficeUpdateData(data)
        },
        ChangeStatus(id){
            const self = this
            self.$swal({
                title: 'Change Status?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Change',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return self.UpdateOfficeStatus(id).then((res)=>{
                        if(res!="OK"){
                            self.$swal('Error',res,'warning')
                        }
                    })
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.$swal('Status Changed!', '', 'success')
                }
            })
        }
    },
    computed : mapGetters(['officeList','officeSearch'])
    
}
</script>