import { createRouter, createWebHistory } from 'vue-router'
import User from '../views/User.vue'
import Office from '../views/Office.vue'
import Region from '../views/Region.vue'
import NotFound from '../views/NotFound.vue'
import CostCode from '../views/CostCode.vue'
import Company from '../views/Company.vue'
import Auth from '../middleware/auth.js'

const routes = [
  {
    path: '/Directory/User',
    name: 'User',
    component: User,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Directory/Office',
    name: 'Office',
    component: Office,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Directory/Region',
    name: 'Region',
    component: Region,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Directory/CostCode',
    name: 'CostCode',
    component: CostCode,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/Directory/Company',
    name: 'Company',
    component: Company,
    meta : {
      middleware : Auth
    }
  },
  {
    path : '/',
    name: 'Home',
    redirect: () => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/Directory/User' }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


function nextFactory (context, middleware, index){
  const subsequentMiddleware = middleware[index]

  if(!subsequentMiddleware){
    return context.next
  }

  return(...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({...context, nextMiddleware})
  }
}

router.beforeEach((to,from,next) =>{
  if(to.meta.middleware){
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = {from, next, router, to}
    const nextMiddleware = nextFactory(context,middleware,1)
    return middleware[0]({...context, nextMiddleware})
  }

  return next()
})

export default router
