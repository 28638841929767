<template>
    <h6 class="fw-bold pt-2">{{labels[index]}}</h6>
    <div v-if="!data">
        <div  class="callout callout-default mt-1" >
            <span class="text-capitalize">No Data Found</span>
        </div>
    </div>
    <div v-else>
        <div v-for="(item,key) in data" :key="key"  class="d-flex justify-content-between" >
            <div :class="item._id!=dataSelected[index] ? inactiveClass : activeClass" @click="ItemClicked(item._id,index)" role="button" 
            style="width:100%;border-top-right-radius:0px;border-bottom-right-radius:0px" :title="item.costCode + ' ' +item.name"
            >
                <span class="text-ellipsis " :class="{'text-warning' : item.status==CostCodeStatusEnum.NOT_IN_USE}">{{item.costCode}} {{item.originalName}}</span>               
            </div>
            <div class="dropend" >
                <button v-show="item._id!=dataSelected[index]" 
                style="border-top-left-radius:0px;border-bottom-left-radius:0px;height:92.6%"
                class="btn btn-default mt-1 pb-1 border-start-0 dropdown-toggle"  data-bs-toggle="dropdown" ></button>
                <button v-show="item._id==dataSelected[index]" class="btn btn-outline-primary mt-1 pb-1 border-start-0 dropdown-toggle" 
                :class="{'btn-outline-primary' : item._id!=dataSelected[index]}" 
                style="border-top-left-radius:0px;border-bottom-left-radius:0px;height:92.6%"
                data-bs-toggle="dropdown" ></button>
                <ul class="dropdown-menu " >
                    <li @click="clickUpdate(item, index)" role="button" data-bs-toggle="modal" data-bs-target="#UpdateCostCodeModal" >
                        <a class="dropdown-item" ><i class="fa-solid fa-edit"></i> Edit Item</a>
                    </li>
                    <li @click="onRemoveClicked(item._id,item.costCode)" v-if="item.status!=CostCodeStatusEnum.NOT_IN_USE" >
                        <a class="dropdown-item text-danger" ><i class="fa-solid fa-trash-can"></i> Remove Item</a>
                    </li>
                    <li @click="onRestoreClicked(item._id,item.costCode)" v-if="item.status==CostCodeStatusEnum.NOT_IN_USE" >
                        <a class="dropdown-item text-warning" ><i class="fa-solid fa-rotate-left"></i> Restore Item</a>
                    </li>
                </ul>
            </div>
        </div>
        
    </div>
</template>

<script>
import {CostCodeStatusEnum} from '@/helpers/utilities.js'
export default {
    name : 'CostCodeColumn',
    props : ['data','clickItem', 'index', 'labels', 'clickUpdate', 'highlighted', 'dataSelected','UpdateIndexSelected', 'onRemoveClicked', 'onRestoreClicked' ],
    data(){
        return {
            selected : '',
            inactiveClass : 'callout callout-default mt-1 border-end-0 pb-1 ',
            activeClass : 'callout callout-primary mt-1 border-end-0 pb-1',
            inactiveClassBtn : 'btn btn-default mt-1 pb-1 border-start-0 dropdown-toggle',
            activeClassBtn : 'btn btn-outline-primary mt-1 pb-1 border-start-0 dropdown-toggle' ,
            CostCodeStatusEnum : CostCodeStatusEnum
        }
    },
    methods : {
        ItemClicked(id){
            const getIndex = this.index + 1
            this.UpdateIndexSelected(this.index,id)
            if(getIndex>2){
                return
            }
            this.clickItem(id,getIndex)
        }
    },
}
</script>
<style scoped>
.text-ellipsis{
    max-width: 270px;
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>

