import { SignJWT, jwtVerify } from 'jose'
import 'dotenv/config'

const secrets = [process.env.VUE_APP_JWT_REQUEST_SECRET,  process.env.VUE_APP_PROJECT_INFO_API_SECRET]


export async function CreateToken(secretIndex = 0) {
    const toBase64 = Buffer.from(secrets[secretIndex], 'base64').toString('binary')
    const jwt = await new SignJWT({})
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setExpirationTime('15m')
    .sign(Uint8Array.from(toBase64, b => b.charCodeAt(0)))

    return jwt
}

export const DecryptToken = async (token, secretIndex) =>{

    if(!token){
        return null;
    }
    const toBase64 = Buffer.from(secrets[secretIndex], 'base64').toString('binary')
    const { payload, protectedHeader } = await jwtVerify(token, Uint8Array.from(toBase64, b => b.charCodeAt(0)))

    return {
        payload: payload, 
        header : protectedHeader
    }
}



