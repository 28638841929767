<template>

  <div class="card">
    <div class="card-header">
      <h4 class="fw-bold">Directory - Region</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4 border border-end-0">
          <RegionForm></RegionForm>
        </div>
        <div class="col-md-8 border">
          <Loader v-if="regionList.totalDocs===undefined"></Loader>
          <RegionList v-else></RegionList>
        </div>
      </div>
    </div>
  </div>

  <Modal idModal="UpdateRegionModal" headerTitle="Edit Region">
    <div class="form-group pb-1">
        <label>Region Name</label>
        <input type="text" class="form-control" v-model="regionDataUpdate.regionName" placeholder="Region Name"/>
         <span class="text-warning" v-if="!regionDataUpdate.regionName && isClicked">Required</span>
    </div>
    <template v-slot:actions>
    <button type="button" ref="closeAddModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
    <button class="btn btn-primary" v-if="regionDataUpdate._id" ref="updateBtn" @click="UpdateRegion"><i class="fa-solid fa-floppy-disk"></i> Save Changes</button>
    </template>
  </Modal>


</template>

<script>
import RegionList from '@/components/directoryInfo/Region/RegionList.vue'
import RegionForm from '@/components/directoryInfo/Region/RegionForm.vue'
import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import { LinkList } from '@/helpers/utilities.js'
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'Region',
  components: {
    RegionList,
    RegionForm,
    Modal,
    Loader
  },
  data(){
    return {
      isClicked :false
    }
  },
  mounted : function(){
    this.GetRegionList().catch()
    this.$store.commit('setActiveLink',LinkList.MAIN_COMPANY)
  },
  methods:{
    ...mapActions(['GetRegionList','UpdateRegionRun']),
    UpdateRegion(){
      const self = this
      const btn = self.$refs.updateBtn
      let data = self.regionDataUpdate
      self.isClicked = true
      if(!data.regionName){
          return
      }
      
      btn.disable = true
      self.isClicked = false

      self.UpdateRegionRun(data).then((res)=>{
          if(res=="OK"){
              self.$refs.closeAddModal.click()
              
          }else{
              self.$swal('Error',res,'warning')
          }
      })
    }
  },
  computed : mapGetters(['regionList','regionDataUpdate'])

}
</script>
