import { GetOfficeDirectoryList, CreateOffice, UpdateOffice, UpdateStatusOffice } from '@/actions/directoryActions.js'

const state ={
    officeList :{
        docs: [],
        totalPages : 0,
    },
    officeDataUpdate : {
        regionId : ""
    },
    search : '',
    page : 1
}
const getters = {
    officeList : state => state.officeList,
    officeDataUpdate : state => state.officeDataUpdate,
    officeSearch : state => state.search,
    officePage : state => state.page
}
const actions = {
    async GetOfficeList({commit},data = { page : 1, search : ''}){
        let search = data.search || ''
        let page = data.page || 1

        commit('setOfficePage',page)
        commit('setOfficeSearch',search)
        const list =  await GetOfficeDirectoryList(search,page).catch()
        
        if(list){
            commit('setOfficeList',list)
        }
    },
    async CreateOfficeRun({dispatch},data){
        const create = await CreateOffice(data).catch()
        if(create=="OK"){
            dispatch('GetOfficeList')
        }

        return create
    },
    async UpdateOfficeRun({dispatch},data){
        const {officePage ,officeSearch} = this.getters

        const update = await UpdateOffice(data).catch()
        if(update=="OK")
        {
            dispatch('GetOfficeList',{ page : officePage, search : officeSearch})
        }

        return update
    },
    async UpdateOfficeStatus(obj, data){
        const update = await UpdateStatusOffice(data).catch()
        if(update=="OK"){
            const officeList = this.getters.officeList.docs
            const index = officeList.findIndex((u)=>u._id===data)
            officeList[index].status = !officeList[index].status
        }

        return update
    },
    AppendOfficeUpdateData({commit},data){
        console.log(data)
        commit('setOfficeDataUpdate',data)
    }

}
const mutations = {
    setOfficeList : (state, data) => (state.officeList = data),
    setOfficeDataUpdate : (state, data) => (state.officeDataUpdate = data),
    setOfficeSearch : (state, data) => (state.officeSearch = data),
    setOfficePage : (state, data) => (state.officePage = data),
}


export default {
    state,
    getters,
    actions,
    mutations
}