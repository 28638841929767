<template>
    <li class="nav-item " role="button">
        <a :class="classList" >
            <i :class="icon"></i>
            <span class="tooltiptext-custom">{{navName}}</span>
        </a>
    </li>
</template>

<script>
export default {
    name : 'NavLink',
    props : ['icon','navName','selected','linkId'],
    data(){
        return {
            classList :'nav-link py-3 tooltip-custom',
            classInitial : 'nav-link py-1 tooltip-custom'
        }
    },
    mounted(){
        if(this.selected==this.linkId){
            this.classList = this.classInitial +' active'
        }
        else{
            this.classList = this.classInitial
        } 
    },
    watch : {
        selected(value){
            if(value==this.linkId){
                this.classList = this.classInitial +' active'
            }
            else{
                this.classList = this.classInitial
            }   
        }
    }
}
</script>