<template>

  <div class="card">
    <div class="card-header">
      <h4 class="fw-bold">Directory - Office</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4 border  border-end-0 ">
          <OfficeForm></OfficeForm>
        </div>
        <div class="col-md-8 border">
          <Loader v-if="officeList.totalDocs===undefined"></Loader>
          <OfficeList v-else></OfficeList>
        </div>
      </div>
    </div>
  </div>

  <Modal idModal="UpdateOfficeModal" headerTitle="Edit Office">
    <div class="form-group pb-1">
        <label>Office Name</label>
        <input type="text" class="form-control" v-model="officeDataUpdate.officeName" placeholder="Office Name"/>
        <span class="text-warning" v-if="!officeDataUpdate.officeName && isUpdateClicked">Required</span>
    </div>
    <div class="form-group pb-1 ">
        <label>Region</label>
        <select class="form-select text-capitalize" v-model="officeDataUpdate.regionId">
            <option value="">Select Region</option>
            <option v-for="(item, key) in regionDropDownList" :key="key" :value="item._id">{{item.regionName}}</option>
        </select>
        <span class="text-warning" v-if="!officeDataUpdate.regionId && isUpdateClicked">Required</span>
    </div>
    <template v-slot:actions>
    <button type="button" ref="closeAddModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
    <button class="btn btn-primary" ref="updateBtn" @click="UpdateOffice"><i class="fa-solid fa-floppy-disk"></i> Save Changes</button>
    </template>
  </Modal>


</template>

<script>
import OfficeList from '@/components/directoryInfo/Office/OfficeList.vue'
import OfficeForm from '@/components/directoryInfo/Office/OfficeForm.vue'
import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import {mapGetters, mapActions} from 'vuex'
import { LinkList } from '@/helpers/utilities.js'

export default {
  name: 'User',
  data(){
    return {
      isUpdateClicked : false
    }
  },
  components: {
    OfficeList,
    OfficeForm,
    Modal,
    Loader
  },
  mounted : function(){
    this.GetRegionListDropdown().catch()
    this.GetOfficeList().catch()
    this.$store.commit('setActiveLink',LinkList.MAIN_COMPANY)
  },
  methods : {
    ...mapActions(['GetOfficeList','GetRegionListDropdown','UpdateOfficeRun']),
    UpdateOffice(){
      const self = this
      const btn = self.$refs.updateBtn
      let data = self.officeDataUpdate
      self.isUpdateClicked = true
      if(!data.officeName || !data.regionId){
        return
      }
      
      btn.disable = true
      self.isUpdateClicked = false

      self.UpdateOfficeRun(data).then((res)=>{
          if(res=="OK"){
              self.$refs.closeAddModal.click()
              
          }else{
              self.$swal('Error',res,'warning')
          }
      })
    }
  },
  computed : mapGetters(['officeList','regionDropDownList', 'officeDataUpdate'])
}
</script>
