import axios from 'axios'
import { CreateToken } from '../helpers/jwtHelpers.js'
import 'dotenv/config'

const MAIN_API = process.env.VUE_APP_REST_MAIN_API + '/api/directory'
// const TOKEN_INDEX_REST = 1
const TOKEN_INDEX_MAIN = 0

axios.defaults.withCredentials = true
//#region User Directory
export const GetUserDirectoryUserList = async (search = "", page = 1) =>{
    return axios.get(`${MAIN_API}/user/list?search=${search.toLowerCase().trim()}&page=${page}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const GetUserDirectoryUserOne = async (id) =>{
    return axios.get(`${MAIN_API}/user/search/${id}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const CreateUser = async (data) =>{
    return axios.post(`${MAIN_API}/user`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateUser = async (data) =>{
    return axios.put(`${MAIN_API}/user/${data._id}`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateUserStatus = async (id) => {
    return axios.put(`${MAIN_API}/user/${id}/status`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const GetUserDirectoryInitialData = async () => {
    return axios.get(`${MAIN_API}/user`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const GetUserInformation = async (id) => {
    return axios.get(`${MAIN_API}/user/${id}`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

//#endregion User Directory

//#region Region Directory
export const GetRegionDirectoryList = async (search = "", page = 1) =>{
    return axios.get(`${MAIN_API}/region/list?search=${search.toLowerCase().trim()}&page=${page}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const GetRegionDirectoryListDropDown = async () =>{
    return axios.get(`${MAIN_API}/region/list?dropdown=true`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const CreateRegion = async (data) =>{
    return axios.post(`${MAIN_API}/region/`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateRegion = async (data) =>{
    return axios.put(`${MAIN_API}/region/${data._id}`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateStatusRegion = async (data) =>{
    return axios.put(`${MAIN_API}/region/${data}/status`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
//#endregion Region Directory


//#region Office Directory
export const GetOfficeDirectoryList = async (search = "", page = 1) =>{
    return axios.get(`${MAIN_API}/office/list?search=${search.toLowerCase().trim()}&page=${page}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const CreateOffice = async (data) =>{
    return axios.post(`${MAIN_API}/office`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}


export const UpdateOffice = async (data) =>{
    return axios.put(`${MAIN_API}/office/${data._id}`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateStatusOffice = async (data) =>{
    return axios.put(`${MAIN_API}/office/${data}/status`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
//#endregion Office Directory


//#region Cost Code Directory
//to be removed
export const GetCostCodeList = async (search = "", page = 1) =>{
    return axios.get(`${MAIN_API}/costcode/list?search=${search.toLowerCase().trim()}&page=${page}`
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

export const GetCostCodeListItems = async (id = '', inUse = '') =>{
    return axios.get(`${MAIN_API}/costcode/list/items`
    ,{
        params : {
            parentId : id,
            inUse : inUse
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}

export const CreateCostCode = async (data) =>{
    return axios.post(`${MAIN_API}/costcode`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const UpdateCostCode = async (data) =>{
    return axios.put(`${MAIN_API}/costcode/${data.id}`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateCostCodeStatus = async (data) =>{
    return axios.put(`${MAIN_API}/costcode/${data}/disable`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const UpdateCostCodeRestore = async (data) =>{
    return axios.put(`${MAIN_API}/costcode/${data}/restore`,
    {}
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}
export const CreateCostCodeTemplate = async (data) =>{
    return axios.post(`${MAIN_API}/costcode/update-template`,
    data
    ,{
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

export const SearchCostCode = async (search ="") =>{
    return axios.get(`${MAIN_API}/costcode/search`,
    {
        params : {
            search : search,

        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>err.response.data)
}

// export const DeleteCostCode = async (id) =>{
//     return axios.delete(`${MAIN_API}/costcode`,
//     {
//         headers :  {
//             Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
//         },
//         data : {
//             id : id
//         }
//     },
//     )
//     .then((res)=>{
//         return res.data
//     }).catch((err)=>err.response.data)
// }


//#endregion Cost Code Directory


//company

export const GetCompanyTypes = async () => {
    return axios.get(`${MAIN_API}/search/company/types`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const SearchCompanies = async (data) => {
    return axios.get(`${MAIN_API}/search/company`,
    {
        params : {
            search :  data.search || '',
            officeId : data.officeId || ''
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const GetOfficeList = async () => {
    return axios.get(`${MAIN_API}/search/office`,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const GetCompanies = async (data) => {
    return axios.get(`${MAIN_API}/company/`,
    {
        params : {
            search : data.search || "",
            page : data.page
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=>console.log(err))
}

export const CreateCompany = async (data) => {
    return axios.post(`${MAIN_API}/company/`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=> err.response.data)
}

export const UpdateCompany = async (data) => {
    return axios.put(`${MAIN_API}/company/`,
    data,
    {
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch((err)=> err.response.data)
}
