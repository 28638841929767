<template>
    <div class="card">
        <div class="card-body">
            <div class="form-group pb-1">
                <label>Office Name</label>
                <input type="text" class="form-control" v-model="officeData.officeName" placeholder="Office Name"/>
                <span class="text-warning" v-if="!officeData.officeName && isClicked">Required</span>
            </div>
            <div class="form-group pb-1 ">
                <label>Region</label>
                <select class="form-select text-capitalize" v-model="officeData.regionId">
                    <option value="">Select Region</option>
                    <option v-for="(item, key) in regionDropDownList" :key="key" :value="item._id">{{item.regionName}}</option>
                </select>
                <span class="text-warning" v-if="!officeData.regionId && isClicked">Required</span>
            </div>
            <div class="d-flex justify-content-end pt-2">
                <button class="btn btn-primary" ref="saveBtn" @click="CreateOffice"><i class="fa-solid fa-floppy-disk"></i> Save Office</button>

            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    name : 'OfficeForm',
    computed : mapGetters(['regionDropDownList']),
    data(){
        return {
            officeData : {
                regionId : ""
            },
            isClicked : false
        }
    },
    methods :{
        ...mapActions(['GetRegionListDropdown','CreateOfficeRun']),
        CreateOffice(){
            const self = this
            const btn = self.$refs.saveBtn
            let data = self.officeData
            self.isClicked = true
            if(!data.officeName || !data.regionId){
                return
            }
            
            btn.disable = true
            self.isClicked = false

            self.CreateOfficeRun(data).then((res)=>{
                if(res=="OK"){
                    self.officeData = { regionId : ""}
                }else{
                    self.$swal('Error',res,'warning')
                }
            })
        }
    }
}
</script>