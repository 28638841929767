<template>

  <div class="">
    <UserList :OpenUpdateModal="OpenUpdateModal" :OpenCreateModal="OpenCreateModal"></UserList>
  </div>

  <Modal idModal="CreateUserModal" headerTitle="Create User" modalSize="modal-xl">
     <div class="form-group">
        <label class="required-style">Company Type </label>
        <select class="form-select" v-model="newUser.companyType" >
            <option v-for="(item, key) in CompanyTypeList" :key="key" :value="item.id">{{item.value}}</option>
        </select>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group" v-if="newUser.companyType==CompanyType.MMOSER">
                <label class="required-style">Search From KC</label>
                <!-- <input type="text" class="form-control" placeholder="John" v-model="newUser.firstName" /> -->
                <Select2 
                    :filterQuery="SearchFromKc"
                    indexValue="firstName"
                    labelName="fullname"
                    placeHolder="Search User from KC"
                    :onOptionClick="AppendSelectedKcUser"
                    ref="select2UserExist"
                ></Select2>
            </div>
            <div class="form-group">
                <label class="required-style">First Name</label>
                <input type="text" class="form-control" placeholder="John" v-model="newUser.firstName" />
                <small class="text-warning" v-if="!newUser.firstName&&SaveClicked">Required</small>
            </div>
            <div class="form-group">
                <label class="required-style">Last Name </label>
                <input type="text" class="form-control" placeholder="Doe" v-model="newUser.lastName" />
                <small class="text-warning" v-if="!newUser.lastName&&SaveClicked">Required</small>
            </div>
            <div class="form-group" v-if="newUser.companyType==CompanyType.MMOSER">
                <label class="required-style">Role</label>
                  <select class="form-select" v-model="newUser.roleId">
                    <option value="">Please Select Role</option>
                    <option v-for="(item, key) in roles" :key="key" :value="item._id">{{item.roleName}}</option>
                </select>
                <small class="text-warning" v-if="!newUser.roleId&&SaveClicked">Required</small>
            </div>
            <div class="form-group"  v-else-if="newUser.companyType==CompanyType.CLIENT">
                <label class="required-style">Role </label>
                <input type="text" class="form-control" readonly value="Client" />
            </div>
            <div class="form-group"  v-else>
                <label class="required-style">Role</label>
                <input type="text" class="form-control" readonly :value="CompanyTypeList[newUser.companyType - 1].value" />
            </div>
            <div class="form-group">
                <label class="required-style">Email </label>
                <input type="text" class="form-control" placeholder="sample@gmail.com" v-model="newUser.email" />
                <small class="text-warning" v-if="(!newUser.email&&SaveClicked) || !IsValidEmail(newUser.email)">Required</small>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group pb-1">
                <label>Office</label>
                <select class="form-select" v-model="newUser.officeId" >
                    <option value="">Select Office</option>
                    <option v-for="(item, index) in offices" :key="index" :value="item._id">{{item.officeName}}</option>
                </select>
                <span class="text-warning" v-if="SaveClicked && !newUser.officeId">Required</span>
            </div>
            <div class="form-group">
                <label>Photo Url</label>
                <input type="text" class="form-control" placeholder="https://url-to-image" v-model="newUser.photoUrl" />
            </div>
            <div class="form-group">
                <label>Address</label>
                <textarea class="form-control" v-model="newUser.address" placeholder="Holiday St. Building #123"></textarea> 
            </div>
            <div class="form-group" v-for="(item, key) in addedContacts" :key="key">
                <div class="d-flex justify-content-between">
                    <label> Contact Number</label>
                    <small v-if="key!=0" @click="RemoveContact(key,1)" class="text-warning" role="button"><i class="fa-solid fa-minus"></i> Remove</small>    
                </div>
                <input type="text" class="form-control" placeholder="+6312345678910" v-model="item.value" />
            </div>
            <div>
                <small @click="AddNewLineContact(1)" class="text-primary" role="button"><i class="fa-solid fa-plus"></i> Add Contact Number</small>
            </div>
            
        </div>
        <div class="col-md-6"  v-if="newUser.companyType!=CompanyType.MMOSER">
                <div class="form-group">
                    <label class="required-style">Company </label>
                    <AutocompleteSelect
                    v-model:optionValue="newUser.companyName"
                    :filterQuery="SearchCompanies"
                    indexValue="companyName"
                    placeHolder="Search Company"
                    :onOptionClick="OnNewCompanyClick"
                    :onClear="OnNewCompanyClear"
                    ></AutocompleteSelect>
                    <small class="text-warning" v-if="!newUser.companyName&&SaveClicked">Required</small>
                </div>
            </div>
            <div class="col-md-6" v-if="newUser.companyType!=CompanyType.MMOSER">
                <div class="form-group">
                    <label class="required-style">Company Address</label>
                    <textarea class="form-control" style="white-space: pre-wrap" v-model="newUser.companyAddress"></textarea>
                    <small class="text-warning" v-if="!newUser.companyAddress&&SaveClicked">Required</small>
                </div>
            </div>
    </div>

    <template v-slot:actions>
        <button type="button" ref="closeModalCreate" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
        <button class="btn btn-primary" ref="CreateBtn" @click="SaveNewUser"><i class="fa-solid fa-floppy-disk"></i> Add User</button>
      </template>
  </Modal>

  <Modal idModal="UpdateUserModal" headerTitle="Edit User" modalSize="modal-xl">
    <fieldset :disabled="isUpdateLoading">
        <div class="form-group">
            <label class="required-style">Company Type </label>
            <select class="form-select" v-model="updatedUser.companyType" >
                <option v-for="(item, key) in CompanyTypeList" :key="key" :value="item.id">{{item.value}}</option>
            </select>
        </div>
        <div class="row">
            <div class="col-md-6">
                
                <div class="form-group">
                    <label class="required-style">First Name</label>
                    <input type="text" class="form-control" placeholder="John" v-model="updatedUser.firstName" />
                    <small class="text-warning" v-if="!updatedUser.firstName&&isUpdateClicked">Required</small>
                </div>
                <div class="form-group">
                    <label class="required-style">Last Name </label>
                    <input type="text" class="form-control" placeholder="Doe" v-model="updatedUser.lastName" />
                    <small class="text-warning" v-if="!updatedUser.lastName&&isUpdateClicked">Required</small>
                </div>
                <div class="form-group" v-if="updatedUser.companyType==CompanyType.MMOSER">
                    <label class="required-style">Role</label>
                    <select class="form-select" v-model="updatedUser.roleId">
                        <option value="">Please Select Role</option>
                        <option v-for="(item, key) in roles" :key="key" :value="item._id">{{item.roleName}}</option>
                    </select>
                    <small class="text-warning" v-if="!updatedUser.roleId&&SaveClicked">Required</small>
                </div>
                <div class="form-group"  v-else-if="updatedUser.companyType==CompanyType.CLIENT">
                    <label class="required-style">Role </label>
                    <input type="text" class="form-control" readonly value="Client" />
                </div>
                <div class="form-group">
                    <label class="required-style">Email </label>
                    <input type="text" class="form-control" placeholder="sample@gmail.com" v-model="updatedUser.email" />
                    <small class="text-warning" v-if="(!updatedUser.email&&isUpdateClicked) || !IsValidEmail(updatedUser.email)">Required</small>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group pb-1">
                    <label>Office</label>
                    <select class="form-select" v-model="updatedUser.officeId" >
                        <option value="">Select Office</option>
                        <option v-for="(item, index) in offices" :key="index" :value="item._id">{{item.officeName}}</option>
                    </select>
                    <span class="text-warning" v-if="isUpdateClicked && !updatedUser.officeId">Required</span>
                </div>
                <div class="form-group">
                    <label>Photo Url</label>
                    <input type="text" class="form-control" placeholder="https://url-to-image" v-model="updatedUser.photoUrl" />
                </div>
                <div class="form-group">
                    <label>Address</label>
                    <textarea class="form-control" v-model="updatedUser.address" placeholder="Holiday St. Building #123"></textarea> 
                </div>
                <div class="form-group" v-for="(item, key) in updatedContacts" :key="key">
                    <div class="d-flex justify-content-between">
                        <label> Contact Number</label>
                        <small v-if="key!=0" @click="RemoveContact(key,2)" class="text-warning" role="button"><i class="fa-solid fa-minus"></i> Remove</small>    
                    </div>
                    <input type="text" class="form-control" placeholder="+6312345678910" v-model="item.value" />
                </div>
                <div>
                    <small @click="AddNewLineContact(2)" class="text-primary" role="button"><i class="fa-solid fa-plus"></i> Add Contact Number</small>
                </div>
                
            </div>
            <div class="col-md-6"  v-if="updatedUser.companyType!=CompanyType.MMOSER">
                <div class="form-group">
                    <label class="required-style">Company </label>
                    <!-- <input type="text" class="form-control" placeholder="Company Name" v-model="updatedUser.company" /> -->
                    <!-- <Select2 
                    :filterQuery="SearchCompanies"
                    indexValue="_id"
                    labelName="companyName"
                    placeHolder="Search Company"
                    ref="select2UsercompanyUpdate"
                    :iniLabel="updatedUser.companyName"
                    :optionValue="updatedUser.companyId"
                    v-model:optionValue="updatedUser.companyId"
                    ></Select2> -->
                    <AutocompleteSelect
                    :optionValue="updatedUser.companyName"
                    v-model:optionValue="updatedUser.companyName"
                    :filterQuery="SearchCompanies"
                    indexValue="companyName"
                    placeHolder="Search Company"
                    :onOptionClick="OnCompanyClick"
                    :onClear="OnCompanyClear"
                    ></AutocompleteSelect>
                    <small class="text-warning" v-if="!updatedUser.companyName&&isUpdateClicked">Required</small>
                </div>
            </div>
            <div class="col-md-6" v-if="updatedUser.companyType!=CompanyType.MMOSER">
                <div class="form-group">
                    <label class="required-style">Company Address</label>
                    <textarea class="form-control" style="white-space: pre-wrap" v-model="updatedUser.companyAddress"></textarea>
                    <small class="text-warning" v-if="!updatedUser.companyAddress&&isUpdateClicked">Required</small>
                </div>
            </div>
        </div>
    </fieldset>

    <template v-slot:actions>
    <button type="button" ref="closeModalUpdate" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
    <button class="btn btn-primary" ref="updateBtn"  @click="updateUser"><i class="fa-solid fa-floppy-disk"></i> Save Changes</button>
    </template>
  </Modal>


</template>
<script src="@/assets/directoryJs/User.js" />
