<template>
    <li class="nav-item" role="button">
        <a :class="classList" aria-current="page">
        <i :class="icon"></i>
            {{navName}}
        </a>
    </li>
</template>

<script>
export default {
    name : 'NavLink',
    props : ['icon','navName','selected','linkId'],
    data(){
        return {
            classList :'nav-link',
            classInitial :'nav-link'
        }
    },
    mounted(){
        if(this.selected==this.linkId)
            this.classList = this.classInitial +' active'
        else
            this.classList = this.classInitial
    },
    watch : {
        selected: function(value) {
            this.value
            if(value==this.linkId)
                this.classList = this.classInitial +' active'
            else
                this.classList = this.classInitial
        }
    }
}
</script>