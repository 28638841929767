import { createStore } from 'vuex'
import UserDirectory from './modules/UserDirectory.js'
import RegionDirectory from './modules/RegionDirectory.js'
import OfficeDirectory from './modules/OfficeDirectory.js'

export default createStore({
  state:{
    activeLink : 1,
  },
  getters : {
    activeLink :state =>  state.activeLink
  },
  actions:{
    ChangeActiveLink :({commit},id) => commit('setActiveLink',id)
  },
  mutations : {
    setActiveLink :  (state,data) => (state.activeLink = data),
  },
  modules: {
    UserDirectory,
    RegionDirectory,
    OfficeDirectory
  }
})
