<template>
    <div class="card border-0">
        <div class="card-header">
            <div class="d-flex justify-content-between">
            <h4 class="fw-bold">Company</h4>
                <button class="btn btn-primary " data-bs-toggle="modal" data-bs-target="#CreateCompanyModal" @click="OpenCompanyModal">+ Add Company</button>
            </div>
        </div>
        <div class="card-body">
            <div class="d-flex justify-content-end">
                <input type="text" class="form-control" placeholder="Search Company" @keyup="SearchCompany" v-model="searchParams.search" />
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="!isLoaded">
                        <tr>
                            <td colspan="5" class="text-center">
                                <Loader />
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="companyList.docs.length==0">
                        <tr>
                            <td colspan="5" class="text-center">
                                No Data Found
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="(item, key) in companyList.docs" :key="key">
                            <td>{{item.companyName}}</td>
                            <td>{{item.email}}</td>
                            <td>{{item.phone}}</td>
                            <td>{{CompanyTypeList[item.companyType -2].value}}</td>
                            <td>
                                <button data-bs-toggle="modal" data-bs-target="#EditCompanyModal" @click="OpenUpdateCompanyModal(item)" class="btn btn-outline-primary btn-action" ><i class="fa-solid fa-edit"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Pagination
                :havePageCount="true"
                :paginationDetails="{totalPages : companyList.totalPages, page : companyList.page}"
                :changePage="ChangePage" 
            ></Pagination>
        </div>
    </div>
    <Modal idModal="CreateCompanyModal" headerTitle="Create Company">
        <div class="form-group">
            <label class="required-style">Company Name </label>
            <input type="text" class="form-control" placeholder="Company Name" v-model="newCompanyData.companyName" />
            <small class="text-warning" v-if="isAddNewClicked&&!newCompanyData.companyName">Required</small>
        </div>
        <div class="form-group">
            <label class="required-style">Office </label>
            <select class="form-select text-capitalize" v-model="newCompanyData.officeId">
                <option value="">Please Select </option>
                <template v-for="(item, key) in officeList" :key="key">
                    <option :value="item._id">{{item.officeName}}</option>
                </template>
            </select>
            <small class="text-warning" v-if="isAddNewClicked&&!newCompanyData.officeId">Required</small>
        </div>
        <div class="form-group">
            <label class="required-style"> Type </label>
            <select class="form-select" v-model="newCompanyData.companyType">
                <option value="">Please Select </option>
                <template v-for="(item, key) in CompanyTypeList" :key="key">
                    <option :value="item.id">{{item.value}}</option>
                </template>
            </select>
            <small class="text-warning" v-if="isAddNewClicked&&!newCompanyData.companyType">Required</small>
        </div>
        <div class="form-group" v-if="newCompanyData.companyType==CompanyType.VENDOR">
            <label >Main Trade</label>
            <select class="form-select" v-model="newCompanyData.mainTrade">
                <option value="">Please Select </option>
                <template v-for="(item, key) in companyTypesData" :key="key">
                    <option :value="item._id">{{item.companyTypeName}}</option>
                </template>
            </select>
        </div>
        <div class="form-group">
            <label class="required-style">Company Address</label>
            <textarea class="form-control" v-model="newCompanyData.address" placeholder="Company Address"></textarea>
            <small class="text-warning" v-if="isAddNewClicked&&!newCompanyData.address">Required</small>
        </div>
        <div class="d-flex justify-content-end" >
            <small class="float-end text-primary" role="button" @click="()=>{ ShowDetails =!ShowDetails }">{{ ShowDetails ?  'Hide' : 'Show'}} Additional Details</small>
        </div>
        <template v-if="ShowDetails">
            <div class="form-group">
                <label >Website</label>
                <input type="text" class="form-control" placeholder="Company Website" v-model="newCompanyData.companyWebsite" />
            </div>
            <div class="form-group">
                <label >Email</label>
                <input type="text" class="form-control" placeholder="Company Email" v-model="newCompanyData.email" />
            </div>
            <div class="form-group">
                <label >Phone</label>
                <input type="text" class="form-control" placeholder="Company Phone" v-model="newCompanyData.phone" />
            </div>
            <div class="form-group">
                <label >Fax</label>
                <input type="text" class="form-control" placeholder="Company Fax" v-model="newCompanyData.fax" />
            </div>
        </template>
        <template v-slot:actions>
            <button type="button" ref="closeAddModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button class="btn btn-primary" @click="CreateCompany" :disabled="isSaving" ><i class="fa-solid fa-floppy-disk"></i> Create</button>
        </template>
    </Modal>
    <Modal idModal="EditCompanyModal" headerTitle="Edit Company">
        <div class="form-group">
            <label class="required-style">Company Name </label>
            <input type="text" class="form-control" placeholder="Company Name" v-model="updateCompanyData.companyName" />
            <small class="text-warning" v-if="isUpdateClicked&&!updateCompanyData.companyName">Required</small>
        </div>
        <div class="form-group">
            <label class="required-style">Office </label>
            <select class="form-select text-capitalize" v-model="updateCompanyData.officeId">
                <option value="">Please Select </option>
                <template v-for="(item, key) in officeList" :key="key">
                    <option :value="item._id">{{item.officeName}}</option>
                </template>
            </select>
            <small class="text-warning" v-if="isUpdateClicked&&!updateCompanyData.officeId">Required</small>
        </div>
        <div class="form-group">
            <label class="required-style"> Type </label>
            <select class="form-select" v-model="updateCompanyData.companyType">
                <option value="">Please Select </option>
                <template v-for="(item, key) in CompanyTypeList" :key="key">
                    <option :value="item.id">{{item.value}}</option>
                </template>
            </select>
            <small class="text-warning" v-if="isUpdateClicked&&!updateCompanyData.companyType">Required</small>
        </div>
        <div class="form-group" v-if="updateCompanyData.companyType==CompanyType.VENDOR">
            <label >Main Trade</label>
            <select class="form-select" v-model="updateCompanyData.mainTrade">
                <option value="">Please Select </option>
                <template v-for="(item, key) in companyTypesData" :key="key">
                    <option :value="item._id">{{item.companyTypeName}}</option>
                </template>
            </select>
        </div>
        <div class="form-group">
            <label class="required-style">Company Address</label>
            <textarea class="form-control" v-model="updateCompanyData.address" placeholder="Company Address"></textarea>
            <small class="text-warning" v-if="isUpdateClicked&&!updateCompanyData.address">Required</small>
        </div>
        <div class="form-group">
            <label >Website</label>
            <input type="text" class="form-control" placeholder="Company Website" v-model="updateCompanyData.companyWebsite" />
        </div>
        <div class="form-group">
            <label>Email</label>
            <input type="text" class="form-control" placeholder="Company Email" v-model="updateCompanyData.email" />
        </div>
        <div class="form-group">
            <label >Phone</label>
            <input type="text" class="form-control" placeholder="Company Phone" v-model="updateCompanyData.phone" />
        </div>
        <div class="form-group">
            <label >Fax</label>
            <input type="text" class="form-control" placeholder="Company Fax" v-model="updateCompanyData.fax" />
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeUpdateModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button class="btn btn-primary" :disabled="isSaving" @click="UpdateCompany" ><i class="fa-solid fa-floppy-disk"></i> Save Changes</button>
        </template>
    </Modal>
</template>

<script src="@/assets/directoryJs/Company.js" />