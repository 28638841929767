import dayjs from "dayjs"

export const statusIcons = [
    {},
    {name : 'Ongoing', icon : 'fas fa-cog fa-spin', color : 'primary'},
    {name : 'Complete', icon : 'fas fa-check-circle', color : 'success'},
    {name : 'Cancelled', icon : 'fas fa-times-circle', color : 'danger'}
]

export const stageIcons = [
    {},
    {name : 'Discovery and Concept', icon : 'fas fa-lightbulb'},
    {name : 'Design Development and Pre-Construction', icon : 'fas fa-shapes'},
    {name : 'Construction', icon : 'fas fa-hard-hat'},
    {name : 'Completion', icon : 'fas fa-handshake'},
]

export const RoleList = {
    ADMIN : 'Administrator',
    PROJECT_DIRECTOR : 'Project Director',
    PROJECT_LEADER: 'Project Leader',
    COMMERCIAL_MANAGER : 'Commercial Manager',
    CONTRACT_MANAGER : 'Contract Manager',
    DESIGNER : 'Designer',
    CONSTRUCTION_MANAGER : 'Construction Manager',
    ACCOUNTING_TEAM : 'Accounting Team',
    CONTRACT_ACCOUNTANT : 'Contract Accountant',
    ENGINEER : 'Engineer',
    CLIENT : 'Client',
    CONSULTANT : 'Consultant',
    VENDOR : 'Vendor',
    SUBCONTRACTOR : 'Subcontractor',
    CONTRACTOR : 'Contractor',
    OTHER : 'Other',
}

export const OfficeList = [
    'Amsterdam','Bangalore',
    'Beijing','Chengdu','Delhi',
    'Guangzhou','Hong Kong','Kuala Lumpur',
    'London','Manila',
    'Melbourne','Mumbai',
    'New York','Paris',
    'San Francisco','Shanghai',
    'Shenzhen','Singapore',
    'Taipei','Toronto','Vancouver'
]

export const CountryList = [
    'Australia','China',
    'France','Hong Kong',
    'India','Malaysia',
    'Netherlands','North America',
    'Philippines','Singapore',
    'Taipei','UK'
]

export const CostCodeStatus = [
    'IN USE',
    'TEMPORARY',
    'ARCHIVE',
]

export const CostCodeStatusEnum = {
    IN_USE : 1,
    TEMPORARY : 2,
    ARCHIVE : 3,
    NOT_IN_USE : 4
}
export const CostCodeTypeNames = ['Default','Internal Fee', 'Internal Fee Percentage Based', 'External Fee', 'Vendor Fee','Discount','External Discount']
export const CostCodeTypes = {
    DEFAULT : 1,
    INTERNAL_FEE : 2,
    INTERNAL_FEE_PERCENTAGE : 3,
    EXTERNAL_FEE : 4,
    VENDOR_FEE : 5,
    DISCOUNT : 6,
    EXTERNAL_DISCOUNT : 7
}


export const LogActionIcons = {
    'LOG IN' : {icon : 'fa-solid fa-right-to-bracket', color : 'info'},
    'LOG OUT' : {icon : 'fa-solid fa-right-from-bracket', color : 'info'},
    'CREATE' : {icon : 'fa-solid fa-floppy-disk', color : 'success'},
    'UPDATE': {icon : 'fa-solid fa-pen-to-square', color : 'primary'},
    'DELETE' : {icon : 'fa-solid fa-trash', color : 'primary'},
    'READ' : {icon : 'fa-solid fa-eye', color : 'info'},
    'ARCHIVE' : {icon : 'fa-solid fa-box-archive', color : 'danger'},
    'ADD TO DIRECTORY' : {icon : 'fa-solid fa-solid fa-floppy-disk', color : 'success'},
    'REMOVE TO DIRECTORY' : {icon : 'fa-solid fa-trash', color : 'danger'}, 
    'GRANT ADMIN ACCESS' : {icon : 'fa-solid fa-lock-open', color : 'danger'}, 
    'REVOKE ADMIN ACCESS' : {icon : 'fa-solid fa-lock', color : 'success'} 
}

export const LinkList = {
    SUMMARY : 1,
    DIRECTORY : 2,
    QUOTATIONS : 3,
    PO : 4,
    DOCUMENTS : 5,
    MAIN_DASHBOARD : 6,
    MAIN_COMPANY : 7,
    MAIN_COMPANY_USER : 8,
    MAIN_COMPANY_OFFICE : 9,
    MAIN_COMPANY_REGION : 10,
    PROJECT_DETAILS : 11,
    PROJECT_DIRECTORY : 12,
    PROJECT_PROCESS : 13,
    BOQ_MASTER_LIST : 14,
    COST_CODES : 15,
}

export const CompanyTypeList = [
    { id : 1, value:'MMoser'},
    { id : 2, value:'Client'},
    { id : 3, value:'Consultant'},
    { id : 4, value:'Contractor'},
    { id : 5, value:'Subcontractor'},
    { id : 6, value:'Vendor'},
    { id : 7, value:'Landlord'},
]

export const CompanyType = {
    MMOSER : 1,
    CLIENT : 2,
    CONSULTANT : 3,
    CONTRACTOR : 4,
    SUBCONTRACTOR : 5,
    VENDOR : 6,
    LANDLORD : 7,
}


export const ContractType = ['','Open Book','Close Book', 'Lump Sum']

export const IsValidEmail = (value) =>{
    let re = /\S+@\S+\.\S+/;
    return re.test(value)
}

export const ReformatDate = (value, format = "DD-MMM-YYYY") =>{
    return dayjs(value).format(format)
}