<template>
    <div class="dropdown">
        <input type="text" 
        autocomplete="off"
        autocorrect="off"
        spellcheck="false"
        @focus="onFocus"
        @blur="onBlur"
        class="form-control select2-design" 
        @keyup="searchExe($event.target.value)" 
        @change="onChange($event.target.value)"
        :placeholder="placeHolder"
        :class="{'text-capitalize' : willCapitalize}" 
        :disabled="disabled"
        v-model="labelValue" />
        <ul class="w-full" :class="{'dropdown-custom-menu': isDropdown,'dropup-custom-menu': !isDropdown,  }" v-show="showDropdown" >
            <li v-if="isLoading"><a class="dropdown-item text-capitalize">Searching...</a></li>
            <template v-else>
                <li v-for="(item, index) in optionList" :key="index" 
                @mousedown="valueAppend(item[indexValue],item[labelName],item)" 
                role="button"
                :title="item[labelName]" >
                    <a class="dropdown-item overflow-hidden" :class="{'text-capitalize' : willCapitalize, 'bg-active' : optionValue==item[indexValue] }" >
                        <div class="small" v-if="subLabel">{{item[subLabel]}}</div>
                        {{item[labelName]}}
                    </a>
                </li>
                <li v-if="optionList.length==0"><a class="dropdown-item text-capitalize" >No Data Found</a></li>
            </template>
        </ul>

        <!-- <input type="hidden" v-model="valueData" id="slc_key_1_hidn"/> -->
    </div>
    
    
</template>

<script>
export default {
    name: 'Select2',
    props :{
        optionValue : String,
        iniLabel : {
            type : String,
            default: ''
        },
        propertyName :{
            type : String,
            default : ''
        },
        placeHolder : {
            type : String,
            default : ''
        },
        filterQuery : {
            type : Function,
            default : async function(){
                return []
            }
        },
        labelName : {
            type: String,
            default : 'label'
        },
        indexValue : {
            type : String,
            default : 'value'
        },
        onOptionClick : {
            type: Function
        },
        dataOptions: {
            type : Array,
            default : ()=>[]
        },
        willCapitalize: {
            type : Boolean,
            default : ()=>true
        },
        subLabel : {
            type: String,
            default : ''
        },
        isDropdown : {
            type :Boolean,
            default : true
        },
        disabled : {
            type :Boolean,
            default : false
        }
    },
    mounted: function(){
        this.onCreate()
    },
    data(){
        return{
            optionList : [],
            optionListInitial : [],
            timer:null,
            trueValue : '',
            labelValue : this.iniLabel,
            isLoading : false,
            currentSearchValue : '',
            showDropdown : false
        }
    },
    watch: {
        optionValue(val) {
            if(!val){
                this.labelValue = ""
            }else{
                 if(this.dataOptions.length!=0&&this.optionValue){
                    const getData = this.dataOptions.find(u=>u[this.indexValue]==this.optionValue)
                    if(getData){
                        this.labelValue = getData[this.labelName]
                    }
                }
            }
        },
        iniLabel(val){
            this.labelValue = val
        },
        dataOptions(val){
            const self = this
            if(val){
                self.optionList = val
            }
        }
    },
    methods:{
        timerSetter(callback){
        const self = this
        
        if(self.timer){
            clearTimeout(self.timer)
            self.timer = null
        }

        self.timer = setTimeout(callback,700)
        },
        searchExe(value){
            const self = this
            if(self.dataOptions.length!=0){
                if(!value){
                    self.optionList = self.dataOptions
                    return
                }

                const filterData = self.dataOptions.filter(u=>u[self.labelName].toLowerCase().indexOf(value.toLowerCase())>-1)
                self.optionList = filterData
            }else{
                // console.log(self.currentSearchValue,value)
                // if(self.currentSearchValue!=value && self.optionValue){
                //     self.currentSearchValue = value
                //     return
                // }
                self.isLoading = true
                self.timerSetter(function(){
                    self.filterQuery(value).then((res)=>{
                        self.isLoading = false
                        self.optionList = res
                        self.optionListInitial = res
                    }).catch(()=>{
                        self.isLoading = false
                        self.optionList = []
                        self.optionListInitial = []
                    })
                })
            }
            
        },
        valueAppend(value, label,item){
            this.labelValue = label

            this.$emit('update:optionValue',value)
            if(typeof this.onOptionClick === "function"){
                this.onOptionClick(item)
            }
        },
        onCreate(){
            this.labelValue = this.iniLabel
            this.optionList = this.dataOptions
            this.optionListInitial = this.dataOptions
        },
        onChange(value){
            const self = this
            if(!value){
                self.$emit('update:optionValue','')
                return
            }
            const filter = self.optionList.filter(u=>u[self.labelName]==value)
            if(filter.length==0)
            {
                self.labelValue = ''
                self.$emit('update:optionValue','')
                return
            }
        },
        onBeforeUpdate(){
            
        },
        ClearData(){
            this.optionList = []
            this.optionListInitial = []
            this.labelValue = ''
            this.$emit('update:optionValue','')
        },
        onFocus(e){
            this.searchExe(e.target.value)
            this.showDropdown = true
        },
        onBlur(){
            this.showDropdown = false
        },
    }
    
}
</script>
<style scoped>
.dropdown-custom-menu{
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(0px, 40px);
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 15px rgb(0 0 0 / 18%);
    font-size: 14px;
    z-index: 1000;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    min-width: 10rem;
    padding: 0.5rem 0;
    z-index: 1000;
    max-height: 400px;
    overflow: auto;
}
.dropup-custom-menu{
    position: absolute;
    inset: auto auto 0px 0px;
    margin: 0px;
    transform: translate(0px, -40px);
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 15px rgb(0 0 0 / 18%);
    font-size: 14px;
    z-index: 1000;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    min-width: 10rem;
    padding: 0.5rem 0;
    z-index: 1000;
    max-height: 400px;
    overflow: auto;
}
.w-full{
    width: 100%;
}
.dropdown-item {
    padding:.25rem 1rem ;
}
.dropdown-item:hover {
    color:#1e2125 ;
    background-color:#E9ECEF ;
}
.select2-design{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: right .75rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    padding: .375rem 2.25rem .375rem .75rem;
}
.bg-active{
    background-color: #0D6EFD;
    color: #FFF;
}
</style>

