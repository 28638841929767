import axios from 'axios'
import { CreateToken } from '../helpers/jwtHelpers.js'
import 'dotenv/config'

const MAIN_API = process.env.VUE_APP_REST_MAIN_API 
const TOKEN_INDEX_MAIN = 0

axios.defaults.withCredentials = true

export const SearchUserFromKC = async (search) =>{
    return axios.get(`${MAIN_API}/api/project/external/user/search`
    ,{
        params : {
            search : search,
        },
        headers :  {
            Authorization : 'Bearer '+ await CreateToken(TOKEN_INDEX_MAIN)
        }
    })
    .then((res)=>{
        return res.data
    }).catch(()=>[])
}
