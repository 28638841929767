import { LinkList, CostCodeStatus,CostCodeStatusEnum ,CostCodeTypeNames} from '@/helpers/utilities.js'
import {GetCostCodeList,SearchCostCode, CreateCostCode, UpdateCostCode, GetCostCodeListItems, UpdateCostCodeStatus, UpdateCostCodeRestore, CreateCostCodeTemplate} from '@/actions/directoryActions.js'
import CostCodeColumn from '@/components/directoryInfo/CostCode/CostCodeColumn.vue'
import Select2 from '@/components/layout/Select2.vue'
import Modal from '@/components/layout/Modal.vue'
export default {
  name : 'CostCode',
  components :{
    Modal,
    Select2,
    CostCodeColumn
  },
  mounted : async function(){
    const self = this
    self.$store.commit('setActiveLink',LinkList.COST_CODES)
    // const getCostCode = await GetCostCodeList().catch()
    // self.costCodeList = getCostCode
    self.GetItemsCostCode('',0)
  },
  methods:{
    async GenerateCostCodeTemplate(){
        this.isGenerating = true
        await CreateCostCodeTemplate()
        this.isGenerating = false
        this.$swal('System Template Updated!','','success')
    },
      GetItemsCostCode(id, index){
        const self = this
        GetCostCodeListItems(id,self.costCodeStatus).then((res)=>{
            self.dataCostCode[index] = res.length!=0 ? res : null
            self.dataCostCode.splice(index + 1)
            self.dataSelected.splice(index)

        })
      },
    //   ChangePage(page){
    //     const self = this
    //     self.page = page
    //     GetCostCodeList(self.searchVal,self.page).then((res)=>{
    //         self.costCodeList = res
    //     }).catch()
    //   },
      searchCostCodeList(search){
          return GetCostCodeList(search)
      },
      searchCostCode(search){
        return SearchCostCode(search)
      },
      AppendCostCodeData(obj){
        this.newCostCode.parentCount = obj.parentCount
        this.newCostCode.hideType = obj.level.indexOf('.') == -1 
      },
      SearchAppend(data){
        const self = this 

        self.highlighted = data._id
        let loop = data.parents
        if(loop.length!=0){
            for(let x= 0;x<loop.length;x++){
                GetCostCodeListItems(loop[x],self.costCodeStatus).then((res)=>{
                    self.dataCostCode[x +1] = res.length!=0 ? res : null
                })
            }
            self.dataCostCode.splice(loop.length)
        }else{
            self.dataCostCode.splice(1)
        }
        loop.push(data._id)
        self.dataSelected = loop
        
        
      },
      UpdateIndexSelected(index,id){
        this.dataSelected[index] = id
      },
      OpenCreateModal(){
        const self = this
        self.newCostCode = {
            isVariation : 'false',
            parentCount : 0,
            hideType : true,
            type : 1
        }
        self.isClicked = false
      },
      CreateCostCodeRun(e){
        const self = this
        const btn = e.target
        const data = self.newCostCode
        self.isClicked = true

        if(!data.parent || data.parentCount ==0){
            if(!data.costCode){
                return
            }
        }
        if(!data.name){
            return
        }

        if(!data.type){
            return
        }
        
        btn.disabled = true
        CreateCostCode(self.newCostCode).then((res)=>{
            btn.disabled = false
            self.isClicked = false
            if(res=="OK"){
                self.$refs.closeAddModal.click()
                
                self.GetAllItems()
                self.$swal('Cost Code Created!','','success')
            }else{
                self.$swal('Error',res,'warning')
            }
        })
      },
      UpdateCostCodeRun(e){
        const self = this
        const btn = e.target
        const data = self.updateCostCode
        
        self.isClickedUpdate = true
        if(!data.id){
            return
        }
        
        if(typeof data.status==='undefined'){
            return
        }
        if(!data.name){
            return
        }

        if(!data.type){
            return
        }

        btn.disabled = true
        
        UpdateCostCode(data).then((res)=>{
            self.isClickedUpdate = false
            btn.disabled = false
            if(res=="OK"){
                self.$refs.closeUpdateModal.click()
                let getPosition = self.dataCostCode[data.indexCode]
                const getIndex = getPosition.findIndex(u=>u._id==data.id)
                if(getIndex!=-1){
                    getPosition[getIndex].originalName = data.name
                    getPosition[getIndex].status = data.status
                }

            }else{
                self.$swal('Error',res,'warning')
            }
        })
      },
      OpenUpdateModal(data,index){
        const self = this
        self.updateCostCode = Object.assign({},data)
        self.updateCostCode.name = self.updateCostCode.originalName
        self.updateCostCode.id = data._id
        self.updateCostCode.indexCode = index
        self.updateCostCode.isItemCostCode = data.isItemCostCode
        self.updateCostCode.type = data.type
        self.isClickedUpdate = false
        
      },
      timerSetter(typed, callback, seachEmpty = true){
        const self = this
        if(!seachEmpty){
            if(!typed){
                return
            }
        }
        
        if(self.timer){
            clearTimeout(self.timer)
            self.timer = null
        }

        self.timer = setTimeout(callback,700)
      },
      DeleteCostCodeRun(id, name){
          const self = this
          self.$swal({
            title: 'Remove ' +name + '?',
            text: 'Cost code will not be usable',
            showCancelButton: true,
            icon: 'warning',
            confirmButtonText: 'Remove',
            showLoaderOnConfirm : true,
            allowOutsideClick: () => !self.$swal.isLoading(),
            preConfirm : ()=>{
                return UpdateCostCodeStatus(id).then((res)=>{
                    if(res!='OK'){
                        self.$swal('Error', res, 'warning')
                    }else{
                        self.GetAllItems()
                    }
                })  
            }
        }).then((res)=>{
            if(res.isConfirmed){
                self.$swal('Cost Code Removed!', '', 'success')
            }
        })
      },
      RestoreCostCodeRun(id, name){
        const self = this
        self.$swal({
          title: 'Restore ' +name + '?',
          text: 'Cost code will be usable again',
          showCancelButton: true,
          icon: 'warning',
          confirmButtonText: 'Restore',
          showLoaderOnConfirm : true,
          allowOutsideClick: () => !self.$swal.isLoading(),
          preConfirm : ()=>{
              return UpdateCostCodeRestore(id).then((res)=>{
                  if(res!='OK'){
                      self.$swal('Error', res, 'warning')
                  }else{
                      self.GetAllItems()
                  }
              })  
          }
      }).then((res)=>{
          if(res.isConfirmed){
              self.$swal('Cost Code Removed!', '', 'success')
          }
      })
    },
      ResetSelectParent(){
        this.$refs.selectCostCode.ClearData()
      },
      GetAllItems(){
        const self = this
        let refreshArray = ['']
        if(self.dataSelected.length!=0){
            if(self.dataSelected.length==3){
                refreshArray.push(...self.dataSelected.slice(0,2))
            }else{
                refreshArray.push(...self.dataSelected)
            }
        }
        for(let x = 0; x<refreshArray.length;x++){
            GetCostCodeListItems(refreshArray[x],self.costCodeStatus).then((res)=>{
                self.dataCostCode[x] = res.length!=0 ? res : null
            })
        }
        
      }
  },
  data(){
    return {
        isGenerating : false,
        CostCodeTypeNames,
        costCodeList : {
            docs: [],
            page : 1,
            totalPages : 0,
        },
        costCodeStatus : 1,
        dataCostCode : [],
        dataSelected : [],
        page : 1,
        searchVal : "",
        newCostCode :{
            isVariation : 'false',
        },
        highlighted : '',
        CostCodeStatus : CostCodeStatus,
        updateCostCode :{},
        isClicked : false,
        isClickedUpdate : false,
        costCodeLabels :['Main Category', 'Sub Category', 'Line Item'],
        CostCodeStatusEnum : CostCodeStatusEnum
    }
  }
}