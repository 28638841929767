import Modal from '@/components/layout/Modal.vue'
import {CompanyTypeList, CompanyType,LinkList} from '@/helpers/utilities.js'
import {GetCompanies, UpdateCompany, CreateCompany, GetCompanyTypes, GetOfficeList} from '@/actions/directoryActions.js'
import Pagination from '@/components/layout/Pagination.vue'
import Loader from '@/components/layout/Loader.vue'
export default {
    name : 'Company',
    components : { Modal, Loader, Pagination },
    data () {
        return {
            officeList : [],
            isLoaded : false,
            companyList :{
                docs: [],
                totalPages : 0,
                totalDocs : 0
            },
            searchParams : {
                search : '',
                page : 1,
            },
            newCompanyData : {},
            isAddNewClicked : false,
            isSaving :false,
            updateCompanyData : {},
            isUpdateClicked : false,
            CompanyTypeList : CompanyTypeList.slice(1),
            companyTypesData : [],
            CompanyType : CompanyType,
            ShowDetails : false,
            timer : null
        }
    },
    mounted : async function(){
        this.ReloadData()
        this.companyTypesData = await GetCompanyTypes()
        this.officeList = await GetOfficeList()
        this.$store.commit('setActiveLink',LinkList.MAIN_COMPANY)
    },  
    methods : {
        timerSetter(callback){
            const self = this
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        ReloadData(){
            const self = this
            self.isLoaded = false

            GetCompanies(this.searchParams).then((res)=>{
                self.isLoaded = true
                self.companyList = res
            })
        },
        SearchCompany(){
            const self = this
            self.timerSetter(function(){
                self.ReloadData()

            })
        },
        ChangePage(page){
            this.searchParams.page = page
            this.ReloadData()
        },
        OpenCompanyModal() {
            this.newCompanyData = {
                mainTrade : '',
                companyType : '',
                officeId : ''
            }
        },
        CreateCompany(){
            const self = this
            const data = self.newCompanyData

            self.isAddNewClicked = true
            if(!data.companyName || !data.address || !data.companyType || !data.officeId){
                return
            }

            self.isAddNewClicked = false
            self.isSaving = true
            CreateCompany(data).then((res)=>{
                self.isSaving = false
                if(res=='OK'){
                self.$refs.closeAddModal.click()
                    
                    self.ReloadData()
                    self.$swal('Company Created!','','success')
                }else{
                    self.$swal('Error',res,'warning')
                }
            })
        },
        OpenUpdateCompanyModal(data) {
            this.updateCompanyData = JSON.parse(JSON.stringify(data))

            this.updateCompanyData.mainTrade = this.updateCompanyData.mainTrade  || ''
        },
        UpdateCompany(e){
            const self = this
            const btn = e.target
            const data = self.updateCompanyData

            self.isUpdateClicked = true
            if(!data.companyName || !data.companyType || !data.officeId){
                return
            }
            self.isUpdateClicked = false

            btn.disabled = true
            self.isSaving = true
            UpdateCompany(data).then((res)=>{
                self.isSaving = false
                if(res=='OK'){
                self.$refs.closeUpdateModal.click()
                    self.ReloadData()
                    self.$swal('Company Updated!','','success')
                }else{
                    self.$swal('Company Update Failed',res,'warning')
                }
            })
        },
    }
}