<template>
  <div class="card border-0">
        <div class="card-header">
            <div class="d-flex justify-content-between">
            <h6 class="fw-bold">Cost Codes</h6>
            <div>
                <button class="btn btn-primary me-1" data-bs-toggle="modal" data-bs-target="#CreateCostCodeModal" @click="OpenCreateModal">+ Add Cost Code</button>
                <button class="btn btn-primary " :disabled="isGenerating" @click="GenerateCostCodeTemplate">Generate System Template</button>
            </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6 pt-2">
                    <!-- <input type="text" class="form-control" placeholder="Type Cost Code Here" v-model="searchVal" @keyup="searchCostCodeList" /> -->
                    <Select2
                    :filterQuery="searchCostCodeList"
                    indexValue="_id"
                    labelName="costName"
                    placeHolder="Type to search for cost code"
                    v-model:optionValue="searchVal"
                    ref="searchCostCode"
                    :onOptionClick="SearchAppend"
                    ></Select2>
                </div>
                <div class="col-md-6 pt-2">
                    <select class="form-select" v-model="costCodeStatus" @change="()=>{ GetItemsCostCode('',0)}">
                        <option value="1">In Use</option>
                        <option value="2">All (Including Inactive)</option>
                    </select>
                </div>
                <div class="col-md-4" v-for="(item,key) in dataCostCode" :key="key">
                    <CostCodeColumn
                    :onRemoveClicked="DeleteCostCodeRun" 
                    :onRestoreClicked="RestoreCostCodeRun" 
                    :data="item" 
                    :clickItem="GetItemsCostCode" 
                    :index="key" 
                    :labels="costCodeLabels" 
                    :clickUpdate="OpenUpdateModal" 
                    :highlighted="highlighted"
                    :dataSelected="dataSelected"
                    :UpdateIndexSelected="UpdateIndexSelected"
                    />
                </div>
            </div>
        </div>
    </div>
    <Modal idModal="CreateCostCodeModal" headerTitle="Create Cost Code">
        <div class="form-group pb-1">
            <label >Parent Cost Code </label>
            <Select2
            :filterQuery="searchCostCode"
            indexValue="_id"
            labelName="costName"
            placeHolder="Type to search for a parent"
            v-model:optionValue="newCostCode.parent"
            :onOptionClick="AppendCostCodeData"
            ref="selectCostCode"
            ></Select2>
            <!-- <span class="text-warning" v-if="!newCostCode.parent  && isClicked">Required</span> -->
        </div>
        <div class="form-group pb-1" v-if="!newCostCode.parent || newCostCode.parentCount ==0">
            <label class="required-style">Cost Code </label>
            <input type="text" class="form-control text-capitalize" v-model="newCostCode.costCode" placeholder="Cost Code Name"/>
            <small class="text-warning" v-if="!newCostCode.costCode && isClicked">Required</small>
        </div>
        <div class="form-group pb-1" >
            <label class="required-style">Name </label>
            <textarea class="form-control text-capitalize" v-model="newCostCode.name"></textarea>
            <small class="text-warning" v-if="!newCostCode.name && isClicked">Required</small>
        </div>
        <div class="form-group pb-1" v-if="!newCostCode.hideType">
            <label class="required-style">Type </label>
            <select class="form-select" v-model="newCostCode.type">
                <option :value="key + 1" v-for="(item,key) in CostCodeTypeNames" :key="key">{{ item }}</option>
            </select>
            <small class="text-warning" v-if="!newCostCode.type&&isClicked">Required</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeAddModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button class="btn btn-primary" ref="createBtn" @click="CreateCostCodeRun"><i class="fa-solid fa-floppy-disk"></i> Create</button>
        </template>
    </Modal>

    <Modal idModal="UpdateCostCodeModal" headerTitle="Update Cost Code">
        <div class="form-group pb-1">
            <label>Cost Code </label>
            <input type="text" class="form-control-plaintext" v-model="updateCostCode.costCode" placeholder="Cost Code Name"/>
        </div>
        <div class="form-group pb-1" v-if="updateCostCode.isVariation==false">
            <label  class="required-style">Name </label>
            <textarea class="form-control" v-model="updateCostCode.name"></textarea>
            <span class="text-warning" v-if="!updateCostCode.name && isClickedUpdate">Required</span>
        </div>
        <div class="form-group pb-1" v-if="updateCostCode.status==CostCodeStatusEnum.NOT_IN_USE">
            <label>Status </label>
            <input type="text" class="form-control-plaintext" disabled value="NOT IN USE">
        </div>
        <div class="form-group pb-1" v-else>
            <label class="required-style">Status </label>
            <select class="form-select" v-model="updateCostCode.status">
                <option :value="key + 1" v-for="(item, key) in CostCodeStatus" :key="key">{{item}}</option>
            </select>
            <span class="text-warning" v-if="!updateCostCode.status && isClickedUpdate">Required</span>
        </div>
        <div class="form-group pb-1" v-if="updateCostCode.isItemCostCode" >
            <label class="required-style">Type </label>
            <select class="form-select" v-model="updateCostCode.type">
                <option :value="key + 1" v-for="(item,key) in CostCodeTypeNames" :key="key">{{ item }}</option>
            </select>
            <small class="text-warning" v-if="!updateCostCode.type&&isClickedUpdate">Required</small>
        </div>
        <template v-slot:actions>
            <button type="button" ref="closeUpdateModal" class="btn btn-outline-danger" data-bs-dismiss="modal">X Cancel</button>
            <button class="btn btn-primary" ref="createBtn" @click="UpdateCostCodeRun"><i class="fa-solid fa-floppy-disk"></i> Save Changes</button>
        </template>
    </Modal>

</template>


<script src="@/assets/directoryJs/CostCode.js" />