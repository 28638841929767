<template>
   <div class="backdrop">
        <div class="modal fade" tabindex="-1" v-bind:id=idModal >
            <div :class="'modal-dialog ' + modalSize">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{headerTitle}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <slot name="actions"></slot>
                    
                </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    export default {
        name : 'Modal',
        props : ['headerTitle', 'idModal', 'modalSize'],
        methods : {
            closeModal()  {
                this.$emit('close')
            }
        } 
    }
</script>
