import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "@/assets/customs/custom.css"
import "@/assets/customs/new-style.css"
import i18n from './translations/i18n.js'


createApp(App).use(i18n).use(store).use(router).use(VueSweetalert2).mount('#app')
