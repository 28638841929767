import { GetUserDirectoryUserList,CreateUser, UpdateUser, UpdateUserStatus,GetUserDirectoryInitialData,GetUserInformation } from '@/actions/directoryActions.js'

const state ={
    offices: [],
    roles : [],
    userList :{
        docs: [],
        totalPages : 0,
        totalDocs : 0
    },
    userData:{
        roleId: "",
        officeId : ""
    },
    userDataUpdate : {
        roleId: "",
        officeId : ""
    },
    userDataInitial : {
        roleId: "",
        officeId : ""
    },
    search : '',
    page : 1
}

const getters ={
    offices : state => state.offices,
    roles : state => state.roles,
    userList : state => state.userList,
    userData : state => state.userData,
    userDataUpdate : state => state.userDataUpdate,
    userDataInitial : state => state.userDataUpdate,
    search : state => state.search,
    page : state => state.page,
}

const actions ={
    async GetUserDirectoryInitial({commit}){
        const intial = await GetUserDirectoryInitialData().catch()
        if(intial){
            commit('setOffices',intial.offices) 
            commit('setRoles',intial.roles) 
        }
    },
    async GetUserList({commit}, data = {search : '', page : 1}){
        let search = data.search || ''
        let page = data.page || 1

        commit('setPage',page)
        commit('setSearch',search)

        const users = await GetUserDirectoryUserList(search,page).catch()
        if(users){
            commit('setUserList',users)
        }
    },
    async CreateUserRun({dispatch,commit}){
        const self = this
        const { userData, userDataInitial } = self.getters
        const create = await CreateUser(userData).catch()
        if(create=="OK"){
            commit('setUserData',userDataInitial)
            await dispatch('GetUserList')
        }

        return create
    },
    async GetUserInfo({commit},data){
        const self = this
        const userData = await GetUserInformation(data).catch()
        const { userDataInitial } = self.getters
        commit('setUserDataUpdate',userDataInitial)
        if(userData){
            commit('setUserDataUpdate',userData)
        }
    },
    async UpdateUserRun({dispatch,commit}){
        const self = this
        const { userDataUpdate ,userDataInitial, search, page } = self.getters
        const update = await UpdateUser(userDataUpdate).catch()
        if(update=="OK"){
            commit('setUserDataUpdate',userDataInitial)
            await dispatch('GetUserList',{search : search, page : page} )
        }

        return update
    },
    async UpdateUserStatusRun(obj,data){
        const statusChange = await UpdateUserStatus(data).catch()
        console.log(statusChange)
        if(statusChange=="OK"){
            const getUsers = this.getters.userList.docs
            const index = getUsers.findIndex((u)=>u._id===data)
            getUsers[index].status = !getUsers[index].status
        }

        return statusChange
    }
}

const mutations ={
    setUserData : (state, data) => (state.userData = data),
    setUserDataUpdate : (state, data) => (state.userDataUpdate = data),
    setOffices : (state, data) => (state.offices = data),
    setRoles : (state, data) => (state.roles = data),
    setUserList : (state, data) => (state.userList = data),
    setPage : (state, data) => (state.page = data),
    setSearch : (state, data) => (state.search = data),
}


export default {
    state,
    getters,
    actions,
    mutations
}

