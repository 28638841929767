<template>
    <div :class="havePageCount ? 'd-flex justify-content-between' : ''">
        <div v-if="havePageCount">
            <small class="text-muted">Showing page {{paginationDetails.page}} of {{paginationDetails.totalPages}}</small>
        </div>
        <nav >
            <ul class="pagination">
                <li :class="paginationDetails.page==1 ? 'page-item disabled' : 'page-item'">
                <a class="page-link" href="#" aria-label="Previous" @click="changePage(paginationDetails.page -1 )" >
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li :class="page==paginationDetails.page ? 'page-item active' : 'page-item'"
                v-for="page in pages"
                @click="changePage(page)" 
                :key="page" >
                <a :class="'page-link'" href="#"  >{{page}}</a>
                </li>
                <li  :class="paginationDetails.page==paginationDetails.totalPages ? 'page-item disabled' : 'page-item'">
                <a class="page-link" href="#" aria-label="Next" @click="changePage(paginationDetails.page+ 1)">
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
    </div>
    
</template>

<script>
export default {
    name : 'Pagination',
    data(){
        return{
            pages : [1]
        }
    },
    mounted :function () {
        const self = this
        self.$nextTick(function () {
            // entire view has been rendered
            self.prepare(self.paginationDetails)
        })
    },
    beforeUpdate() {
        const self = this
        self.prepare(self.paginationDetails)
    },
    props : {
        paginationDetails : Object,
        changePage : Function,
        havePageCount : {
            type :Boolean,
            default : false
        }
    },
    methods : {
        prepare (pagination){
            const self = this
            let newPages = []
            let pagedLast = pagination.totalPages -4 < 1 ? 1 : pagination.totalPages -4
            if(pagination.page>=pagedLast){
                for(let i=pagedLast;i<=pagination.page +4;i++){
                    newPages.push(i)
                    if(pagination.totalPages==i){
                        break;
                    }
                }
            }
            else{
                for(let i=pagination.page;i<=pagination.page +4;i++){
                    newPages.push(i)
                }
            }

            self.pages =  newPages
        },
        
    }

}
</script>

<style>
.page-item.disabled > a{
    background-color:  #dee2e6 !important;

}
</style>