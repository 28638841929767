<template>
    <div class="dropdown" 
         >
        <input type="search" 
        autocomplete="off"
        autocorrect="off"
        spellcheck="false"
        class="form-control" 
        @focus="onFocus"
        @blur="onBlur"
        @keyup="searchExe($event.target.value)" 
        @input="onInput" 
        :placeholder="placeHolder"
        :class="{'text-capitalize' : willCapitalize}" 
        v-model="labelValue" />
        <ul class="dropdown-custom-menu w-full" v-show="showDropdown" >
            <li v-if="isLoading"><a class="dropdown-item text-capitalize">Searching...</a></li>
            <template v-else>
                <li v-for="(item, index) in optionList" :key="index" 
                role="button"
                @mousedown="valueAppend(item[indexValue].trim(),item)" 
                :title="item[indexValue]" >
                    <a class="dropdown-item overflow-hidden" :class="{'text-capitalize' : willCapitalize, 'bg-active' : optionValue==item[indexValue] }" >
                        {{item[indexValue]}}
                    </a>
                </li>
                <li v-if="optionList.length==0"><a class="dropdown-item text-capitalize" >No Data Found</a></li>
            </template>
        </ul>

        <!-- <input type="hidden" v-model="valueData" id="slc_key_1_hidn"/> -->
    </div>
    
    
</template>

<script>
export default {
    name: 'Select2',
    props :{
        optionValue : String,
        placeHolder : {
            type : String,
            default : ''
        },
        filterQuery : {
            type : Function,
            default : async function(){
                return []
            }
        },
        indexValue : {
            type : String,
            default : 'value'
        },
        onOptionClick : {
            type: Function
        },
        onClear : {
            type: Function
        },
        dataOptions: {
            type : Array,
            default : ()=>[]
        },
        willCapitalize: {
            type : Boolean,
            default : ()=>true
        },
    },
    mounted: function(){
        this.onCreate()
    },
    data(){
        return{
            optionList : [],
            optionListInitial : [],
            timer:null,
            labelValue : '',
            trueValue : '',
            isLoading : false,
            showDropdown : false,
        }
    },
    watch: {
        optionValue (val){
            this.labelValue = val
        },
        labelValue(val){
            this.$emit('update:optionValue',val)
        },
        dataOptions(val){
            const self = this
            if(val){
                self.optionList = val
            }
        }
    },
    methods:{
        onInput(e){
            this.searchExe(e.target.value)
            if(typeof this.onOptionClick === "function" && !e.target.value){
                this.onClear()
            }
        },
        onFocus(e){
            this.searchExe(e.target.value)
            this.showDropdown = true
        },
        onBlur(){
            this.showDropdown = false
        },
        timerSetter(callback){
        const self = this
        
        if(self.timer){
            clearTimeout(self.timer)
            self.timer = null
        }

        self.timer = setTimeout(callback,700)
        },
        searchExe(value){
            const self = this
            if(self.dataOptions.length!=0){
                if(!value){
                    self.optionList = self.dataOptions
                    return
                }

                // const filterData = self.dataOptions.filter(u=>u[self.labelName].toLowerCase().indexOf(value.toLowerCase())>-1)
                // console.log(filterData)
                // self.optionList = filterData
            }else{
                self.isLoading = true
                self.timerSetter(function(){
                    self.filterQuery(value).then((res)=>{
                        self.isLoading = false
                        self.optionList = res
                        self.optionListInitial = res
                    }).catch(()=>{
                        self.isLoading = false
                        self.optionList = []
                        self.optionListInitial = []
                    })
                })
            }
            
        },
        valueAppend(value,item){
            this.labelValue = value
            this.$emit('update:optionValue',value)
            if(typeof this.onOptionClick === "function"){
                this.onOptionClick(item)
            }
        },
        onCreate(){
            this.labelValue = this.optionValue
            this.optionList = this.dataOptions
            this.optionListInitial = this.dataOptions
        },
        ClearData(){
            this.optionList = []
            this.optionListInitial = []
            this.labelValue = ''
            this.$emit('update:optionValue','')
        }
    }
    
}
</script>
<style scoped>
.dropdown-custom-menu{
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(0px, 40px);
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 15px rgb(0 0 0 / 18%);
    font-size: 14px;
    z-index: 1000;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    min-width: 10rem;
    padding: 0.5rem 0;
    z-index: 1000;
    max-height: 400px;
    overflow: auto;
}
.dropdown-item {
    padding:.25rem 1rem ;
}
.dropdown-item:hover {
    color:#1e2125 ;
    background-color:#E9ECEF ;
}
.w-full{
    width: 100%;
}
.bg-active{
    background-color: #0D6EFD;
    color: #FFF;
}
</style>

