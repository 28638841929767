<template>
    <div class="card">
        <div class="card-header">
            <div class="d-flex justify-content-between">
                <h4 class="fw-bold">User Directory</h4>
                <button class="btn btn-primary"
                @click="OpenCreateModal"
                data-bs-toggle="modal" data-bs-target="#CreateUserModal"
                ><i class="fa-solid fa-plus"></i> Add User</button>
            </div>
        </div>
        <div class="card-body">
            <div class="pb-3">
                <input type="text" placeholder="Seach User here..." class="form-control" @keyup="searchUser($event.target.value)"/>
            </div>
            <div class="clearfix"></div>
            <div class="d-flex justify-content-end" v-if="userList.totalDocs">
                <Pagination
                    :paginationDetails="{totalPages : userList.totalPages, page : userList.page}"
                    :changePage="ChangePage" 
                ></Pagination>
            </div>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Office</th>
                            <th>Created At</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="text-capitalize">
                        <tr v-if="userList.totalDocs==0">
                            <td colspan="6" class="text-center">No Data Found</td>
                        </tr>
                        <tr v-for="(item, index) in userList.docs"  :key="index">
                            <td>{{item.fullname}}</td>
                            <td>{{item.roleId?.roleName}}</td>
                            <td>{{item.officeId?.officeName}} - {{item.regionId?.regionName}}</td>
                            <td>{{formatDate(item.createAt)}}</td>
                            <td>
                                <span class="badge bg-primary text-white" v-if="item.status">Active</span>
                                <span class="badge bg-danger text-white" v-else>Inactive</span>
                            </td>
                            <td>
                                <button class="btn btn-primary pe-1 ps-1 pt-0 pb-0 me-1" @click="OpenUpdateModal(item._id)" data-bs-toggle="modal" data-bs-target="#UpdateUserModal" title="Edit"><i class="fa-solid fa-edit"></i></button>
                                <button class="btn btn-danger pe-1 ps-1 pt-0 pb-0 me-1" title="Change Status" @click="BlockUser(item._id)" ><i class="fa-solid fa-lock"></i></button>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Pagination from '@/components/layout/Pagination.vue'
import {ReformatDate} from '@/helpers/utilities.js'
export default {
    
    name : 'UserList',
    components : {
        Pagination
    },
    props : ['OpenUpdateModal','OpenCreateModal'],
    data(){
        return {
            timer : null
        }
    },
    methods : {
        formatDate (value){
            return ReformatDate(value)
        },
        timerSetter(typed, callback, seachEmpty = false){
            const self = this
            if(!seachEmpty){
                if(!typed){
                return
                }
            }
            
            if(self.timer){
                clearTimeout(self.timer)
                self.timer = null
            }

            self.timer = setTimeout(callback,700)
        },
        searchUser(search){
            const self = this
            self.timerSetter(search,function(){
                self.GetUserList({search : search, page : 1}).catch()
            },true)
        },
        ChangePage (page){
            this.GetUserList({search : this.search, page : page}).catch()
        },
        ...mapActions(['GetUserList','GetUserDirectoryInitial','GetUserInfo','UpdateUserStatusRun']),
        BlockUser(id){
            const self = this
            self.$swal({
                title: 'Change Status?',
                showCancelButton: true,
                icon: 'warning',
                confirmButtonText: 'Change',
                showLoaderOnConfirm : true,
                allowOutsideClick: () => !self.$swal.isLoading(),
                preConfirm : ()=>{
                    return self.UpdateUserStatusRun(id).then((res)=>{
                        if(res!='OK'){
                            self.$swal('Error', res, 'warning')
                        }
                    })  
                }
            }).then((res)=>{
                if(res.isConfirmed){
                    self.$swal('Status Changed!', '', 'success')
                }
            })
        }
    },
    computed : mapGetters(['userList','search'])
}
</script>